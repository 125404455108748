import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CustomPreviewComponent } from 'src/app/Common-components/custom-preview/custom-preview.component';
import { PreviewComponent } from 'src/app/Common-components/preview/preview.component';
import { ShowContractComponent } from 'src/app/Common-components/show-contract/show-contract.component';
import { ApiservicesService } from 'src/app/services/apiservices.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.css'],
})
export class ContractListComponent {
  public listprogressBar: any = false;
  public loader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ['_id'];
  tabledata_detail_skip: any = ['_id', 'usertype'];
  public tabledatatalist: any = [];
  tablename = 'contract_list';
  editroute = 'admin-dashboard/contract-management/edit-contract';
  updateendpoint = 'contract-management/contract-update';
  deleteendpoint = 'contract-management/contract-management-delete';
  datacollection: any = 'contract-management/contract-management-list';

  searchendpoint = '';
  date_search_endpoint: any = 'contract-management/contract-management-list';
  date_search_source: any = 'contract-management/contract-management-list';
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ['_id'];
  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  modify_header_array: any = {
    name: 'Contract Name',
    opportunity_name: 'Opportunity',
    status: 'Status',
    created_on: 'Created On',
  };

  search_settings: any = {
    textsearch: [{ label: 'Search By Contract Name', field: 'name' }],
    search: [
      // {
      //   label: 'Search By Disposition Name',
      //   field: 'disposition_id',
      //   type:'autocomplete',
      //   value: '' ,
      //   serversearchdata: { endpoint: 'disposition/search-form-autocomplete-disposition-name' },
      //   multiple: true,
      // },
    ],

    datesearch: [
      {
        startdatelabel: 'Created On Start Date',
        enddatelabel: 'Created On End Date',
        submit: 'Search',
        field: 'created_on',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
    ],

    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.statusarray },
    ],
  };

  sortdata: any = {
    type: 'desc',
    field: 'created_on',
    options: ['name', 'opportunity_name', 'created_on', 'status'],
  };

  public cookieData: any = {};

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [],
  };

  libdata: any = {
    basecondition: {},
    detailview_override: [
      { key: 'name', val: 'Contract Name' },
      { key: 'opportunity_name', val: 'Opportunity' },
      { key: 'status', val: 'Status' },
      { key: 'created_on', val: 'Created On' },
    ],
    updateendpoint: 'contract-management/contract-management-status-change',
    hidedeletebutton: false,
    hideviewbutton: true,
    hideeditbutton: true,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: 'contract-management/contract-management-status-change',

    deleteendpointmany: 'contract-management/contract-management-delete',
    tableheaders: ['name', 'opportunity_name', 'status', 'created_on'],

    custombuttons: [
      {
        label: 'View Contract',
        type: 'listner',
        id: 'viewcontract_btn',
        tooltip: 'View Contract',
        name: 'viewcontract',
        classname: 'viewContract',
      },
      {
        label: 'Convert to PDF',
        type: 'listner',
        id: 'converttopdf_btn',
        tooltip: 'Convert to PDF',
        name: 'converttopdf',
        classname: 'convertToPdf',
      },
      {
        label: 'Edit',
        type: 'listner',
        id: 'edit_btn',
        tooltip: 'Edit',
        name: 'edit',
        classname: 'edit_btn',
      },
      {
        label: 'Preview',
        type: 'listner',
        id: 'preview_btn',
        previewlist: [
          'name',
          'opportunity_name',
          'description',
          'status',
          'created_on',
        ],
        tooltip: 'Preview',
        classname: 'previewButton',
      },
    ],
    colpipes: [
      { type: 'datetime', col: 'created_on', format: 'MMMM D YYYY, h:mm A' },
    ],
  };

  public taxonomy_updatetable: boolean = false;
  public api_url_for_managebanner = environment.api_url;
  public jwttokenformanagebanner = '';

  public dispositionID: any;

  updatetable: boolean = false;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private _bottomSheet: MatBottomSheet,
    public cookieService: CookieService,
    private apiService: ApiservicesService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe({
      next: (res: any) => {
        this.tabledatatalist =
          res?.response?.results?.res && Array.isArray(res.response.results.res)
            ? res.response.results.res
            : [];

        this.apiService
          .getHttpDataPost(
            'contract-management/contract-management-list-count',
            {
              condition: {
                limit: 10,
                skip: 0,
              },
              searchcondition: {},
              sort: {
                type: 'desc',
                field: 'created_on',
              },
              project: {},
              token: '',
            }
          )
          .subscribe((response: any) => {
            if (response && response.count) {
              this.date_search_source_count = response.count;
            }
          });
      },
      error: (error: any) => {},
    });
  }

  onLiblistingButtonChange(val: any) {}

  listenLiblistingChange(data: any = null) {
    if (data?.custombuttonclick?.btninfo?.id == 'edit_btn') {
      this.editListItem(data.custombuttonclick.data);
    }

    if (
      data.action === 'custombuttonclick' &&
      data.custombuttonclick.btninfo.id === 'preview_btn' &&
      data.custombuttonclick.data
    ) {
      this.dialog.open(CustomPreviewComponent, {
        panelClass: ['custom-modalbox',"descripcation-modal-box"],
        data: {
          key: data.custombuttonclick.btninfo.previewlist,
          value: data.custombuttonclick.data,
        },
      });
    }

    if (
      data.action === 'custombuttonclick' &&
      data.custombuttonclick.btninfo.id === 'viewcontract_btn' &&
      data.custombuttonclick.data
    ) {
      this.dialog.open(ShowContractComponent, {
        panelClass: ['custom-modalbox', 'contractprev-modalbox'],
        data: data.custombuttonclick.data,
      });
    }

    if (
      data.action === 'custombuttonclick' &&
      data.custombuttonclick.btninfo.id === 'converttopdf_btn' &&
      data.custombuttonclick.data
    ) {
      this.dialog.open(convertToPDF, {
        panelClass: ['custom-modalbox', 'converttopdf-modalbox'],
        data: data.custombuttonclick.data,
      });
    }
  }

  editListItem(item: any) {
    if (item) {
      this.goToPage(
        `admin-dashboard/contract-management/edit-contract/${item._id}`
      );
    }
  }

  goToPage(pageName: string) {
    this.router.navigate([`${pageName}`]);
  }
}

// << ------------------- Send Contract Confirmation Component ------------------ >>

@Component({
  selector: 'convert-to-pdf-modal',
  templateUrl: './convert-to-pdf.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, SharedModule, CommonModule],
})
export class convertToPDF {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<convertToPDF>,
    private elementRef: ElementRef,
    private apiService: ApiservicesService,
    public matSnackbar: MatSnackBar,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    console.log('data====>',data);
  }

  public loader: boolean = false;

  public downloadLink: any = null;

  ngOnInit() {
    this.loader = true;

    let requestBody = {
      html_body:this.data.key == "contract"? this.data.pdfhtml:this.data.export_content_data,
      name:this.data.key == "contract"? "contract": this.data.name,
    };
    

    this.apiService
      .getHttpDataPost(
        'contract-management/convert-contract-to-pdf',
        requestBody
      )
      .subscribe({
        next: (response: any) => {
          console.log(response);
          if (response.status == 'success') {
            if (response.res) {
              this.downloadLink = response.res;
              this.loader = false;
            } else {
              this.matSnackbar.open('Something went wrong !!', '', {
                duration: 5000,
              });
              this.closeDialog();
              this.loader = false;
            }
          } else {
            this.matSnackbar.open('Something went wrong !!', '', {
              duration: 5000,
            });
            this.closeDialog();
            this.loader = false;
          }
        },
        error: (error: any) => {
          this.matSnackbar.open('Something went wrong !!', '', {
            duration: 5000,
          });
          this.closeDialog();
          this.loader = false;
        },
      });
  }

  downloadPDF() {

    // const a = document.createElement('a')
    // const blob = new Blob([this.downloadLink],{ type: 'application/pdf' })
    // const url = window.URL.createObjectURL(blob)
    // a.href = url
    // console.log(a, a.href, url, blob);
    // a.download = 'sanket.pdf'
    // a.click()
    // window.URL.revokeObjectURL(url)
    // a.remove()

    window.open(this.downloadLink, '_blank');
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

// << ------------------- Send Contract Confirmation Component ------------------ >>
