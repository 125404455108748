import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';

@Component({
  selector: 'app-custom-preview',
  templateUrl: './custom-preview.component.html',
  styleUrls: ['./custom-preview.component.css']
})
export class CustomPreviewComponent {

  public datakey: any;
  public datavalue: any;
  public created_on: any;
  public updated_on: any
  public created_at: any
  public originval: any
  public startDate: any;
  public startDateval: any
  public endDate: any;
  public day: any = [];
  public stateEndTime: any;
  public bookedTime: any;
  public appointmentTime: any;
  public bookingNotes: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<CustomPreviewComponent>) {
    this.datakey = this.data.key
    this.datavalue = this.data.value
  }

  ngOnInit() {
    this.datafilter(this.datakey, this.datavalue)

    console.log("dataValue", this.datavalue);
    console.log("data", this.data);
    this.originval = this.data.origin

    if (this.data.value.sun === true) {
      this.day.push(" Sunday")
    }
    if (this.data.value.mon === true) {

      this.day.push(" Monday")
    }
    if (this.data.value.tues === true) {

      this.day.push(" Tuesday")
    }
    if (this.data.value.wed === true) {

      this.day.push(" Wednesday")
    }
    if (this.data.value.thurs === true) {

      this.day.push(" Thursday")
    }
    if (this.data.value.fri === true) {

      this.day.push(" Friday")

    }
    if (this.data.value.sat === true) {

      this.day.push(" Saturday")

    }

  }

  datafilter(required_keys: any, dataset: any) {

    for (const key of this.datakey) {
      console.log("key=====>>>>", key);


      if (key === "created_on") {
        this.created_on = moment(dataset[key]).format('MMMM Do YYYY, h:mm:ss a')
      }
      if (key === "updated_on") {
        this.updated_on = moment(dataset[key]).format('MMMM Do YYYY, h:mm:ss a')
      }
      if (key === "created_at") {
        this.created_at = moment(dataset[key]).format('MMMM Do YYYY, h:mm:ss a')
      }
      if (key === "start_date") {
        this.startDate = this.datavalue.start_date ? moment(this.datavalue.start_date).format('MMMM Do YYYY') : this.datavalue.start_date
      }
      if (key === "end_date") {
        this.endDate = this.datavalue.end_date ? moment(this.datavalue.end_date).format('MMMM Do YYYY') : this.datavalue.end_date
      }
      if (key === "start_date_format") {
        this.startDateval = this.datavalue.start_date_format_unix ? moment(this.datavalue.start_date_format_unix).format('MMMM Do YYYY') : this.datavalue.start_date_format_unix
      }
      if (key === "startUnixVal") {
        this.appointmentTime = this.datavalue.startUnixVal ? moment(this.datavalue.startUnixVal).format('MMMM Do YYYY') : this.datavalue.startUnixVal
      }
      if (key === "booking_time_unix") {
        this.bookedTime = this.datavalue.booking_time_unix ? moment(this.datavalue.booking_time_unix).format('MMMM Do YYYY, h:mm:ss a') : this.datavalue.booking_time_unix
      }
      if (key === "start_end_time_human") {
        this.stateEndTime = this.datavalue?.start_end_time_human ? this.datavalue.start_end_time_human : ""
      }
      if (key === "notes") {
        this.bookingNotes = this.datavalue?.notes ? this.datavalue.notes : ""
      }

    }

  }

  closePreview() {
    this.dialogRef.close()
  }

}