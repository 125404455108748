import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { convertToPDF } from 'src/app/admin-dashboard/contract-management/contract-list/contract-list.component';
import { ApiservicesService } from 'src/app/services/apiservices.service';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css'],
})
export class ContractComponent {
  public rep_name: any;
  public beto_name: any;

  public location: any;
  public city: any;
  public state: any;
  public zip: any;

  public signature: any;
  public date: any;
  // public name: any
  // public email: any
  // public phone: any
  // public address: any
  // public city: any
  public paramsId: any;
  public contractData: any;
  public update_id: any;
  public filedstatus: boolean = false;
  // public myForm: any
  public loginForm: FormGroup;
  public validflag: any = {};
  public unic_value: string = '';
  public contractForm: FormGroup;
  public loader: boolean = false;
  unamePattern = '^[a-z0-9_-]{2,5}$';
  isValidFormSubmitted = null;
  public errors: any;
  public userData: any;
  public sign_flag: boolean = false;
  public today: any = moment(Date.now()).format('MM/DD/YYYY');
  public control: any;
  public dawnloadPdf: any = false;
  public storeValue: any = {};
  constructor(
    public activateRoute: ActivatedRoute,
    private apiService: ApiservicesService,
    private cookieService: CookieService,
    public router: Router,
    public matSnackBar: MatSnackBar,
    private contract: FormBuilder,
    public dialog: MatDialog
  ) {}

  // Function to disable all form controls in the FormGroup
  disableFormControls() {
    const formControls = Object.keys(this.loginForm.controls);

    for (const controlName of formControls) {
      this.loginForm.get(controlName)?.disable();
    }
  }

  ngOnInit() {
    this.activateRoute.data.subscribe((response: any) => {
      console.log('activatedRoute========>', response);
      if (response.data.status === 'success') {
        this.userData = response?.data?.results ? response.data.results[0] : [];
        console.log('tabledatatalist=====>', this.userData);
        this.loginForm = this.contract.group({
          date: [this.today, Validators.required],
          beto_name: ['Beto Paredes', Validators.required],
          rep_name: [
            this.userData?.name ? this.userData.name : '',
            Validators.required,
          ],
          // location: [this.userData?.location ? this.userData.location : '', Validators.required,],
          city: [
            this.userData?.city ? this.userData.city : '',
            Validators.required,
          ],
          state: [
            this.userData?.city ? this.userData.state : '',
            Validators.required,
          ],
          zip: [
            this.userData?.city ? this.userData.zip : '',
            Validators.required,
          ],
          //  address:[this.contractData?.address?this.contractData.address:'',Validators.required,],
          //  city:[this.contractData?.city?this.contractData.city:'',Validators.required,],
          signature: [
            this.contractData?.signature ? this.contractData.signature : '',
            Validators.required,
          ],
        });
      }
    });

    console.log(
      'this is activeroute ',
      this.activateRoute,
      window.location.pathname
    );

    this.paramsId = this?.activateRoute?.snapshot?.params['_id'];
    console.log('paramid===========>', this.paramsId);

    if (
      this.activateRoute?.snapshot?.params &&
      this.activateRoute?.snapshot?.params['_id']
    ) {
      this.activateRoute.data.subscribe((response: any) => {
        console.log(' jewl data edit', response);
        if (response.data && response.data.status == 'success') {
          console.log('this is my data');
          this.contractData = response.data.results.results[0];
          this.update_id = response.data.results.results[0]['_id'];
          this.today = moment(this.contractData.date).format('MM/DD/YYYY');

          this.filedstatus = true;

          // this.disableFormControls()

          console.log('this is form status', this.filedstatus);
          this.loginForm = this.contract.group({
            date: [
              this.contractData?.date ? this.contractData.date : '',
              Validators.required,
            ],
            rep_name: [
              this.contractData?.rep_name ? this.contractData.rep_name : '',
              Validators.required,
            ],
            // location: [this.contractData?.location ? this.contractData.location : '', Validators.required,],
            city: [
              this.contractData?.rep_name ? this.contractData.city : '',
              Validators.required,
            ],
            state: [
              this.contractData?.email ? this.contractData.state : '',
              Validators.required,
            ],
            zip: [
              this.contractData?.phone ? this.contractData.zip : '',
              Validators.required,
            ],
            // name:[this.contractData?.rep_name?this.contractData.rep_name:'',Validators.required,],
            // email:[this.contractData?.email?this.contractData.email:'',Validators.required,],
            // phone:[this.contractData?.phone?this.contractData.phone:'',Validators.required,],
            //  address:[this.contractData?.address?this.contractData.address:'',Validators.required,],
            //  city:[this.contractData?.city?this.contractData.city:'',Validators.required,],
            signature: [
              this.contractData?.signature ? this.contractData.signature : '',
              Validators.required,
            ],
          });
        }
      });
    }

    // console.log();

    if (
      window.location.pathname.includes('/admin-dashboard/contract-sign') ||
      this.activateRoute?.snapshot?.params['_id']
    ) {
      this.sign_flag = true;
      console.log('hitttttttttttttttttttt', this.sign_flag);
    }
  }

  phoneFormatting = (data: any) => {
    console.log('event phone', data);
    if (data.split('').length <= 14) {
      var formatted = this.formatPhoneText(data);

      // let phone = formatted
      // console.log("phone formatein",phone);
      // console.log("phone formatein",this.loginForm.controls.phone);
      // this.loginForm['controls']['phone']={...this.loginForm['controls']['phone'],value:phone}
    }
  };

  formatPhoneText(value: any) {
    value = value.trim().replaceAll('-', '');
    value = value.trim().replaceAll('(', '');
    value = value.trim().replaceAll(')', '');
    value = value.trim().replaceAll(' ', '');

    if (value.length > 3 && value.length <= 6)
      value = '(' + value.slice(0, 3) + ')' + ' ' + value.slice(3);
    else if (value.length > 6)
      value =
        '(' +
        value.slice(0, 3) +
        ')' +
        ' ' +
        value.slice(3, 6) +
        '-' +
        value.slice(6);
    return value;
  }

  runing(event: any) {
    console.log('this is running', event.target.name);
    let value = String(event.target.value);
    let key = String(event.target.name);
    window.localStorage.setItem(key, value);
    if (event.target.name === 'rep_name') {
      this.errors = { ...this.errors, rep_name: '' };
    }

    // if(event.target.name === 'date'){
    //   this.errors={...this.errors,date:''}
    // }

    // if (event.target.name === 'location') {
    //   this.errors = { ...this.errors, location: '' }
    // }
    if (event.target.name === 'city') {
      this.errors = { ...this.errors, city: '' };
    }
    if (event.target.name === 'state') {
      this.errors = { ...this.errors, state: '' };
    }
    if (event.target.name === 'zip') {
      this.errors = { ...this.errors, zip: '' };
    }
    // if(event.target.name === 'name'){
    //   this.errors={...this.errors,name:''}
    // }
    // if(event.target.name === 'email'){
    //   this.errors={...this.errors,email:''}
    // }
    // if(event.target.name === 'phone'){
    //   this.errors={...this.errors,phone:''}
    //   this.phoneFormatting(this.loginForm?.['controls']?.['phone']?.['value'])
    //   console.log("this is phoone",this.loginForm?.['controls']?.['phone']?.['value']);

    // }
    // if(event.target.name === 'address'){
    //   this.errors={...this.errors,address:''}
    // }
    // if(event.target.name === 'city'){
    //   this.errors={...this.errors,city:''}
    // }

    if (event.target.name === 'signature') {
      this.errors = { ...this.errors, signature: '' };
    }
  }

  getName() {
    let val1 = window.localStorage.getItem('rep_name') ?? '';
    let val2 = window.localStorage.getItem('city') ?? '';
    let val3 = window.localStorage.getItem('state') ?? '';
    let val4 = window.localStorage.getItem('zip') ?? '';
    let val5 = window.localStorage.getItem('signature') ?? '';

    this.storeValue = {
      rep_name: val1,
      city: val2,
      state: val3,
      zip: val4,
      signature: val5,
    };
    console.log('hittttttttt', this.storeValue);
  }
  contractSubmit() {
    // console.log("this is form status",this.filedstatus);

    // console.log();
    console.log('this is contract form data', this.contractData);

    console.log('aaaaaaaaaaaa', this.loginForm.value);

    if (this.loginForm.status === 'INVALID') {
      this.matSnackBar.open('Signature is required', 'ok', {
        duration: 3000,
      });
    }

    // if(this.loginForm?.['controls']?.['date']?.['errors']?.['required']){
    //   this.errors={...this.errors,date:"date in Required"}
    // }

    if (this.loginForm?.['controls']?.['rep_name']?.['errors']?.['required']) {
      this.errors = { ...this.errors, rep_name: 'Rep name in Required' };
    }
    // if (this.loginForm?.['controls']?.['location']?.['errors']?.['required']) {
    //   this.errors = { ...this.errors, location: "Location in Required" }
    // }
    if (this.loginForm?.['controls']?.['state']?.['errors']?.['required']) {
      this.errors = { ...this.errors, name: 'State in Required' };
    }
    if (this.loginForm?.['controls']?.['city']?.['errors']?.['required']) {
      this.errors = { ...this.errors, email: 'City in Required' };
    }
    if (this.loginForm?.['controls']?.['zip']?.['errors']?.['required']) {
      this.errors = { ...this.errors, phone: 'Zip in Required' };
    }
    // if(this.loginForm?.['controls']?.['address']?.['errors']?.['required']){
    //   this.errors={...this.errors,address:"Address in Required"}
    // }
    // if(this.loginForm?.['controls']?.['city']?.['errors']?.['required']){
    //   this.errors={...this.errors,city:"City in Required"}
    // }
    if (this.loginForm?.['controls']?.['signature']?.['errors']?.['required']) {
      this.errors = { ...this.errors, signature: 'Signature is Required' };
    } else {
      // return this.rep_name;
      const login_user_details = this.cookieService.get('login_user_details')
        ? JSON.parse(this.cookieService.get('login_user_details'))
        : {};

      console.log('aaaaaaaaaaaa', this.loginForm.value);

      const keyaree = Object.keys(this.loginForm.value);

      this.loader = true;
      // if()
      this.apiService
        .getHttpDataPost('contract/contract-add', {
          is_rep_contract_signed: 1,
          has_betoparedes_access: 1,
          user_id: login_user_details.userinfo._id,
          ...this.loginForm.value,
        })
        .subscribe({
          next: (response: any) => {
            if (response.status === 'success') {
              console.log('success', response);
              this.loader = false;
              let namearr = this.loginForm.value.rep_name.split(' ');
              console.log('namearr', namearr);
              let oldcookie = JSON.parse(
                this.cookieService.get('login_user_details')
              );
              let newcookie = {
                lastLoginTime: oldcookie.lastLoginTime,
                token: oldcookie.token,
                token_expiry: oldcookie.token_expiry,
                userinfo: {
                  ...oldcookie.userinfo,
                  has_betoparedes_access: 1,
                  is_rep_contract_signed: 1,
                  name: this.loginForm.value.rep_name,
                  city: this.loginForm.value.city,
                  state: this.loginForm.value.state,
                  zip: this.loginForm.value.zip,
                  firstname: namearr[0],
                  lastname: namearr[1],
                },
              };
              console.log('oldcookie', oldcookie);

              this.cookieService.set(
                'login_user_details',
                JSON.stringify(newcookie),
                { path: '/' }
              );
              console.log('newcookie', newcookie);
              this.matSnackBar.open('Contract Signed Successfully!', 'Ok', {
                duration: 3000,
              });
              setTimeout(() => {
                window.location.href = `rep-dashboard/w9-form/`;
              }, 2000);
            }
          },
          error: (error: any) => {
            this.loader = false;
            this.matSnackBar.open('Something Went Wrong!', 'Ok', {
              duration: 3000,
            });
          },
        });
    }
  }

  pdfConverter() {
    this.dawnloadPdf = true;
    let pdfId: any = document.getElementById('contract_form')?.innerHTML;
    this.dialog.open(convertToPDF, {
      panelClass: ['custom-modalbox', 'converttopdf-modalbox'],
      data: { pdfhtml: pdfId, key: 'contract' },
    });
  }

  clickPrint() {
    window.print();
  }
}
