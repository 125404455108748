import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isPositiveValue'
})
export class IsPositiveValuePipe implements PipeTransform {

  transform(value: any,): boolean {
    return value.length>0 ;
  }

}
