import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiservicesService } from 'src/app/services/apiservices.service';

@Component({
  selector: 'app-attend',
  templateUrl: './attend.component.html',
  styleUrls: ['./attend.component.css']
})
export class AttendComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private apiService: ApiservicesService,public matSnackBar: MatSnackBar,private dialogRef: MatDialogRef<AttendComponent>,){}

  public loader:Boolean=false
  @Input() followFormStatus:any

  ngOnInit(){
    console.log("attend data",this.data);
    
  }

  confirm(){
    this.loader=true

    this.apiService.getHttpDataPost('followup/update-lead-attend', {id:this.data.data._id,attend:true}).subscribe({
      next:(response:any)=>{
        this.loader=false
        this.matSnackBar.open(response.message, "ok", {
          duration: 3000
        })
        this.data.followFormStatus=!this.data.followFormStatus
        
          this.dialogRef.close();
        
      },
      error:(error:any)=>{
        this.loader=false
      }
    })

    this.dialogRef.close();


  }

  reject(){

  }

  attendclose(){
    this.dialogRef.close();
    this.followFormStatus=!this.followFormStatus

  }

}
