import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { ApiservicesService } from '../services/apiservices.service';
// import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { OtpVerificationComponent } from '../otp-verification/otp-verification.component';
import { environment } from 'src/environments/environment';
// import { MatFormFieldModule } from "@angular/material/form-field";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  public loginForm: FormGroup;
  public loader: boolean = false;
  public not_Varifed: boolean = false
  private userIp: string = 'false';
  private unsubscriber: Subject<void> = new Subject<void>();
  public errors: any
  public loginButtonStatus: boolean = false

  public lastRoute: any = ""




  hide = true;
  constructor(private apiService: ApiservicesService, private cookieService: CookieService, public router: Router, public matSnackBar: MatSnackBar, public dialog: MatDialog) {

    

    // this.lastRoute = this.router?.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString()

    // console.log(this.lastRoute)
    

    this.loginForm = new FormGroup({
      email: new FormControl(''),
      password: new FormControl(''),
    })
  }
  ngOnInit() {
    console.log("NODE_ENV===============+>", environment.stage);

    if(this.lastRoute == "/admin-dashboard/contract-management/request-contracts-list") {
      sessionStorage.setItem("lastroute", this.lastRoute);

      console.log(sessionStorage.getItem('lastroute'));
      
    }

    console.log("this is login form value", this.loginForm);
    history.pushState(null, '');

    this.apiService.getHttpData('user/build-connection').subscribe({
      next: (getIpResp) => {
        console.log("Database Connected");
      }
    })

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');

    });

  }


  handleInputChange(data: any) {
    console.log(data.target.name);
    
    if (data.target.name === "email") {
      this.errors = { ...this.errors, email: '' }
    }

    if (data.target.name === "password") {
      this.errors = { ...this.errors, password: '' }
    }

    if (data.key === "Enter") {
      this.login()
    }
  }


  login() {

    if (this.loginForm.value.email === "") {
      this.errors = { ...this.errors, email: "Email is required" }
    }
    if (this.loginForm.value.password === "") {
      this.errors = { ...this.errors, password: "Password in required" }
    }

    else {

      console.log("loginForm", this.loginForm.value)

      this.loader = true;
      console.log("loginForm", this.loginForm.value)

      this.apiService.getHttpDataPost(environment.stage == "local" ? "user/login" : "user/login-new", this.loginForm.value).subscribe({

        next: (response: any) => {

          if (response.status === "success") {
            if(response.isOffline) {
              this.loader = false;
            this.matSnackBar.open("Come to our September 23rd, 2024 sales program kick off!", "", {
              duration: 5000
            })
            
          } else {
            this.cookieService.set('login_user_details', JSON.stringify(response.results), { path: '/', });
            this.cookieService.set('loggedin_user', 'true', { path: '/', });
            this.loader = false;

            this.matSnackBar.open(response.message, "Ok", {
              duration: 3000
            })


            if (response.results.userinfo.user_type === 'is_admin') {
              console.log("response.results.user_type", response.results.user_type);

              if(sessionStorage.getItem('lastroute')) {

                let lastRoute = sessionStorage.getItem('lastroute')
                
                this.router.navigateByUrl(`${lastRoute}`);

                setTimeout(() => {
                  sessionStorage.removeItem('lastroute')
                }, 1500);

              } else {
                this.router.navigateByUrl(`/admin-dashboard`);
              }
            }

            if (response.results.userinfo.user_type === 'is_rep') {
              console.log("response.results.user_type", response.results);
              if (response.results.userinfo.is_rep_contract_signed == 0 || !response.results.userinfo.is_rep_contract_signed) {
                this.router.navigateByUrl(`/rep-dashboard/sign-contract`);
              } else if (response.results.userinfo.is_w9_form_signed == 0) {
                this.router.navigateByUrl(`/rep-dashboard/w9-form`);

              } else if (response.results.userinfo.has_betoparedes_access == 1 || response.results.userinfo.has_betoparedes_access == 2) {
              // } else if (response.results.userinfo.is_rep_contract_signed == 1 && response.results.userinfo.is_w9_form_signed == 0) {

              if(response.results.userinfo.unique_identifier && response.results.userinfo.percentage != 100) {
                this.router.navigateByUrl(`/trainings`);
              } else {
                this.router.navigateByUrl(`/rep-dashboard`);

              }


              }
            }
            
          }
          } else {
            this.matSnackBar.open("Something went wrong!!", "", {
              duration: 3000
            })
          }


        },


        error: (error: any) => {
          // console.log('error--------',error)
          if (error.results?.user === "inactiv") {
            this.matSnackBar.open(error.results.message, "", {
              duration: 3000
            })
          } else {
            this.matSnackBar.open(error.message, "", {
              duration: 3000
            })
          }
          this.loader = false;

          // this.matSnackBar.open(error.message, "", {
          //   duration: 3000
          // })

          console.log("this is loging error data", error);
          //  if(error?.results?.user && error?.results?.user === 'inactiv'){
          //   this.matSnackBar.open(error.results.message, "Ok", { duration: 3000})
          //  }
          //  else{
          //   this.matSnackBar.open(error.message, "Ok", { duration: 3000})
          //  }


          console.log("email_dfsdf", this.loginForm.value.email);



          if (error.not_Varifed === true) {
            
            // document.cookie = `emailverificationcodesend=4{this.loginForm.value.email};expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
            this.cookieService.set('emailverificationcodesend',this.loginForm.value.email , 0.00139);

            /////////////////////     Api CAll for otp verification     /////////////////////
            // this.apiService.getHttpData("user/request-verification-code?username=" + this.loginForm.value.email).subscribe((res: any) => {
            // })


            //  setTimeout(() => {
            // this.dialog.open(OtpVerificationComponent, {
            //      data:{"not_Varifed":error.not_Varifed,"email":this.loginForm.value.email.split(" ").join(""),login:this.login},
            //    });
            //  },1000)

            const dialogRef = this.dialog.open(OtpVerificationComponent, {

              data: { "not_Varifed": error.not_Varifed, "email": this.loginForm.value.email.split(" ").join(""), login: this.login },

            })

            this.loginButtonStatus = true

            dialogRef.afterClosed().subscribe((result) => {
              console.log("this is login opt dilog close",result);
              if(result != 'cancel'){
                this.login()
                this.loginButtonStatus = false
              }

            })

          }


        }
      })





    }







  }





  handlenter(e: any) {
    this.hide = true
    console.log("enter click", e.key);

    if (e.key === "Enter") {
      this.login()
    }

  }

  handlevisibl(event: any) {
    event.preventDefault()
    this.hide = !this.hide
  }


}


