import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ApiservicesService } from '../services/apiservices.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.css'],
  // imports:[MatFormFieldModule, MatInputModule, MatIconModule],
})
export class OtpVerificationComponent {

  formConfig:any = {}
  loader:boolean=false
  otp:any
  public inputValue:any
  public disabel_button:boolean = false 
  public display:any 

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,private dialogRef: MatDialogRef<OtpVerificationComponent> ,private apiservice: ApiservicesService, public matSnackBar: MatSnackBar) {
    
  }

  timer(minute:any) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        console.log("finished");
        this.disabel_button = false
        clearInterval(timer);
      }
    }, 1000);
  }

  

  ngOnInit() {
    // this.create_on=moment(this.data.create_on).format('MMMM Do YYYY, h:mm:ss a');
    // this.updated_on=moment(this.data.updated_on).format('MMMM Do YYYY, h:mm:ss a');
    console.log("data",this.data);
    this.disabel_button = true
    this.timer(2);
    
    this.otpform()
  }


  otpform(){
    this.formConfig={
      successmessage: 'Added Successfully !!',
      submittext: 'Submit',
      resettext:'Resend OTP',
      apiUrl: this.apiservice.baseUrl,
      canceltext: 'Cancel',
      hidereset: false,
      jwttoken: '',
      fields: [
        {
          label: 'OTP',
          name: 'otp',
          value: '',
          type: 'text',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'OTP is Required' },
          ],
        }
      ]
    }

  }

  onSubmit(val:any){
    val.preventDefault()
    console.log("otp_form_data",val);
    console.log("inputvalue",this.inputValue);
  }
  handelSubmit(val:any){
    if (val === "submit" && this.inputValue) {
      this.loader =true

      this.apiservice.getHttpDataPost("user/signup-verification",{"username":this.data.email,"otp": this.inputValue.trim()}).subscribe({
        next:(response:any)=>{
          console.log("response",response);
          if(response.status === "success"){
            this.matSnackBar.open(response.message, "Ok", {
              duration: 3000
               })
            this.dialogRef.close()
          }
        this.loader =false

        },
        error:(error:any)=>{
          console.log(error);
          if(error.status === "error"){
            this.matSnackBar.open(error.message, "Ok", {
              duration: 3000
               })
          }
        this.loader =false

          
        }
      })
    }else if(val === "cancel"){
      this.dialogRef.close('cancel')
    }else if(val === "resend_otp"){
      this.loader =true
      this.apiservice.getHttpData("user/request-verification-code?username="+this.data.email).subscribe((res:any)=>{
        if(res.status==="success"){
          this.disabel_button = true
          this.timer(2);
        }
        this.loader =false
        this.matSnackBar.open(res.message, "Ok", {
          duration: 3000
           })
      })
    }
  }



    

  listenFormFieldChange(val:any){
    console.log("form_val",val);

    if(val.field === "formcancel"){
      //document.cookie = `login_user_details=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
      //document.cookie = `loggedin_user=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
      this.dialogRef.close('cancel')

    }else if (val.fieldval === "formresetbutton" && val.field === "formreset"){
      this.apiservice.getHttpData("user/request-verification-code?username="+this.data.email).subscribe((res:any)=>{
        console.log("response",res);
        this.matSnackBar.open(res.message, "Ok", {
          duration: 3000
           })
        
      })
    }else if(val.fieldval === "success" && val.field === "fromsubmit"){
      this.loader =true

      this.apiservice.getHttpDataPost("user/signup-verification",{"username":this.data.email,"otp":val.fromval.otp}).subscribe({
        next:(response:any)=>{
          console.log("response",response);
          if(response.status === "success"){
            this.matSnackBar.open(response.message, "Ok", {
              duration: 3000
               })
            this.dialogRef.close()
            this.data.login()
          }
        this.loader =false

        },
        error:(error:any)=>{
          console.log(error);
          if(error.status === "error"){
            this.matSnackBar.open(error.message, "Ok", {
              duration: 3000
               })
          }
        this.loader =false

          
        }
      })
      
    }
    
  }
}


