import { Component } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  public footerYear:any = ""

  ngOnInit(){
    this.footerYear = moment().format("YYYY")
  }

}
