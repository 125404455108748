import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiservicesService } from 'src/app/services/apiservices.service';
import moment from 'moment';

@Component({
  selector: 'app-reschedule-followup',
  templateUrl: './reschedule-followup.component.html',
  styleUrls: ['./reschedule-followup.component.css'],
})
export class RescheduleFollowupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiservicesService,
    public matSnackBar: MatSnackBar,
    private dialogRef: MatDialogRef<RescheduleFollowupComponent>
  ) { }

  public loader: Boolean = false;
  public configFormData: any = null;
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public formSubmitLoader: boolean = false
  @Input() followFormStatus: any;

  ngOnInit() {
    console.log('leaddata data', this.data);
    this.initailForm();
  }

  confirm() {
    this.loader = true;

    this.dialogRef.close();
  }

  reject() { }

  attendclose() {
    this.dialogRef.close();
    this.followFormStatus = !this.followFormStatus;
  }

  initailForm() {
    // console.log("lead form data", this.formValue.rep_id);

    this.configFormData = {
      successmessage: 'Added Successfully !!',
      submittext: this.data.pushsection ? 'Update' : 'Reschedule',
      apiUrl: this.apiService.baseUrl,
      resettext: 'Reset',
      canceltext: 'Cancel',
      hidereset: true,
      jwttoken: '',
      fields: [
        {
          label: 'Date',
          name: 'date',
          type: 'date',
          class: 'datefield',

          minDate: new Date(),
          validations: [
            {
              rule: 'required',
              message: 'Date filed is required',
            },
          ],
        },
        {
          label: 'Time',
          name: 'start_time',
          type: 'timepicker2',
          format: 24,
          class: 'timefield',

          validations: [
            {
              rule: 'required',
              message: 'timepicker 2 field Needs to be required',
            },
          ],
        },
        {
          label: 'Notes',
          name: 'notes',
          type: 'textarea',
          class: 'notesfield',

          validations: [{ rule: 'required', message: 'Notes is required' }],
        },
      ],
    };
  }
  listenFormFieldChange(val: any) {
    console.log('Vllllll', val, this.data);
    // return
    if (val.field == "fromsubmit") {
      let date = `${moment(val.fromval.date.toString()).format('YYYY-MM-DD')} ${val.fromval.start_time}`
      let dataset = {

        notes: val.fromval.notes,

        start_time: val.fromval.start_time,
        start_time_unix: moment(date).valueOf(),
        date: moment(val.fromval.date).valueOf(),
        // rescheduled: true,
        id: this.data.data._id,
        pushed:true

      }

      this.formSubmitLoader = true;
      this.apiService.getHttpDataPost(
        'followup/follow-edit', dataset
      ).subscribe((response: any) => {
        console.log("response", response);
        this.formSubmitLoader = false;
        if (response) {
          if(this.data.pushsection){
            this.matSnackBar.open('Follow-up pushed successfully !!', '', {
              duration: 3000,
            });
          }else{
            this.matSnackBar.open('Follow-up rescheduled successfully !!', '', {
              duration: 3000,
            });
          }
        

          setTimeout(() => {
            this.dialogRef.close();
          }, 4000);

        }
      })

    }

    if (val.field && val.field == "formcancel") {
      this.dialogRef.close();
    }
  }
}
