import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiservicesService } from '../services/apiservices.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-add-edit-attendee-commponent',
  templateUrl: './add-edit-attendee-commponent.component.html',
  styleUrls: ['./add-edit-attendee-commponent.component.css'],
})
export class AddEditAttendeeCommponentComponent {

  public currentData:any={}
  public attendee_mail:any=[{"name":'',"email":''}]
  public errorObj:any=[]
  public submitionState:boolean=false
  public identifier:any
  public submitionCapabelete:boolean=false
  public loader:boolean = false


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private apiService: ApiservicesService , public dialogRef: MatDialogRef<AddEditAttendeeCommponentComponent>,public matSnackBar: MatSnackBar){
    this.currentData = data
    this.attendee_mail=data.atten_email
    this.identifier = data.identifier
  }


  handelChange(){
    console.log('change happened');
  }


  addAttendy(){
    this.attendee_mail.push({"name":'',"email":''})
  }

  handelInputChange(event:any,index:number){
    let name = event.target.name
    let value = event.target.value
    this.attendee_mail[index][name] = value
    this.checkValidation(this.attendee_mail)
  }

  removeAttendee(index:number){
    this.attendee_mail.splice(index, 1)
  }

  async addAttendySubmitFuntion(){
    this.submitionState = true
    let error = this.checkValidation(this.attendee_mail)
    let iserror = false
    for(let i = 0;i < error.length;i++){
      let item = this.errorObj[i]
      if( item && (item.name || item.email)){
        iserror = true
      }
    }
    if(!iserror){
      this.loader = true

      this.apiService.getHttpCalendarPost("calendar-event/add-attendee",{"atten_email": this.attendee_mail,"identifier": this.identifier}).subscribe({
        next: (responce: any) => {
          if(responce.result.status === 200){
            this.loader = false
            this.dialogRef.close()
            this.matSnackBar.open('Attendee Updated Successfully', 'Ok', {
              duration: 3000,
            });
          }
        }
      })
    }
  }


  checkValidation(attandymail:any){
    
    for(let i = 0;i < attandymail.length ; i++){
      let item = attandymail[i]
      if(!item.name){
        let obj = {...this.errorObj[i]}
        obj['name']="this Field is requer" 
        this.errorObj[i] = {...obj}
      }else if(item.name){
        if(this.errorObj && this.errorObj[i] && this.errorObj[i].name){
         delete this.errorObj[i]['name'] 
        }
      }
      if(!item.email){
        let obj = {...this.errorObj[i]}
        obj['email']= "this Field is requer" 
        this.errorObj[i]={...obj}
      }else if (item.email){
        let reg = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        if(!item.email.match(reg)){
          let obj1 = {...this.errorObj[i]}
          console.log("obj1", obj1);
          
          obj1['email']= "invalid Mail" 
          this.errorObj[i]={...obj1}
        }else{
          if(this.errorObj && this.errorObj[i] && this.errorObj[i].email){
            delete this.errorObj[i]['email']
          }
        }
      }
    }
    return this.errorObj
  }
}
