export const environment = {
  stage: 'dev',
  production: true,
  // api_url_lambda: "https://a0ef6m9zyg.execute-api.us-west-1.amazonaws.com/prod/",
  // api_url: "https://pujyvua5lk.execute-api.us-east-1.amazonaws.com/dev/",
  api_url: "https://dev.api.salesbackoffice.betoparedes.com/",
  // api_url: "https://dgqilciy9h2po.cloudfront.net/",
  astrology_api_url: "https://json.astrologyapi.com/v1/",
  username: '625019',
  password: '0cfbc4f3b606854ab036efb3f7dbccc4',
  frontendurl: "https://betoparedes.com/",
  fileuploadbaseurl: "https://d36fwfwo4vnk9h.cloudfront.net/",
  url: 'https://d37pbainmkhx6o.cloudfront.net/'   ,             //////////////////////////divine url///////
  without_base_url: "https://i526y91jwg.execute-api.us-east-2.amazonaws.com/dev/",   //////////////////////////without base url for calendar management///////
  ndaLink: "https://devnda.betoparedes.com/",
  beto_calender_redirect_link:"https://calendar.betoparedes.com/discoverycall-for-sales-dev",
  nethen_calender_redirect_link:"https://calendar.betoparedes.com/gameplan-call-for-sales-dev",
  base_salesbackoffice_path_url_link:"https://dev.salesbackoffice.betoparedes.com",
  affiliate_contrect:"https://dev.frontend.betoparedes.com/",
  frontend_url:"https://www.dev.salesbackoffice.betoparedes.com/"

};