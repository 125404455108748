<div class="preview_modal">
  <h1 class="preview_header">preview</h1>

  <ng-container *ngIf="data.source === 'lead'; then leadpreview; else preview">
  </ng-container>

  <ng-template #leadpreview>
    <div class="preview_contain">
      <ng-container *ngFor="let data of datakey">
        <!-- <div > -->

        <p class="preview_data">
          <span
            ><strong
              >{{
                data === "user_type_str"
                  ? "User Type"
                  : data === "product_name"
                  ? "source" : data === "addedby_email" ? "Added By Email" : data === "addedby_name" ? "Added By" : data === "addedfor_email" ? "Added For Email" : data === "addedfor_name" ? "Added For" : data === "clickCount" ? "Click Count" : data === "conversionCount" ? "Conversion Count"
                  : data.replace("_", " ") 
              }}
              :</strong
            ></span
          >
          <span class="preview_value">
            {{
              data == "status"
                ? datavalue[data] === 1
                  ? "Active"
                  : "Inactive"
                : data === "created_on"
                ? created_on
                : data === "updated_on"
                ? updated_on
                : data === "created_at"
                ? created_at
                : datavalue[data]
                ? datavalue[data]
                : "N/A"
            }}
          </span>
          <!-- <span><strong>{{data === "product_name" ? "source":""}} :</strong></span>  -->
        </p>
        <!-- </div> -->
      </ng-container>
    </div>
  </ng-template>

  <ng-template #preview>
    <div>
      <ng-container *ngFor="let data of datakey">
        <p class="preview_data">
          <span
            ><strong
              >{{
                data === "created_at"
                  ? "Joined on"
                  : data === "created_on"
                  ? "Joined on"
                  : data === "user_type_str"
                  ? "User Type"
                  : data === "addedby_email" ? "Added By Email" : data === "addedby_name" ? "Added By" : data === "addedfor_email" ? "Added For Email" : data === "addedfor_name" ? "Added For" : data === "clickCount" ? "Click Count" : data === "conversionCount" ? "Conversion Count" : data.replaceAll("_", " ")
              }}
              :</strong
            ></span
          >
          <span class="preview_value">
            {{
              data == "status"
                ? datavalue[data] === 1
                  ? "Active"
                  : "Inactive"
                : data === "created_on"
                ? created_on
                : data === "updated_on"
                ? updated_on
                : data === "created_at"
                ? created_at
                : data === "email"
                ? datavalue[data]
                : datavalue[data]
                ? datavalue[data]
                : "N/A"
            }}
          </span>
        </p>
        <!-- </div> -->
      </ng-container>
    </div>
  </ng-template>

  <li *ngIf="data.video">
    <span class="title image_title">Video :</span>
    <span class="image_wraper">
      <section *ngFor="let video of data.video">
        <ng-container
          *ngIf="video.fileservername; then video_first; else video_second"
        ></ng-container>
        <ng-template #video_first>
          <video controls>
            <source
              [src]="
                'https://d37pbainmkhx6o.cloudfront.net/' +
                video.baseurl +
                video.fileservername
              "
            />
          </video>
        </ng-template>
        <ng-template #video_second> N/A </ng-template>
      </section>
    </span>
  </li>

  <!-- <span class="close-btn-modal" (click)="closePreview()">X</span> -->

  <span
    mat-button
    mat-dialog-close
    class="close-btn-modal material-icons"
    mat-raised-button
    matTooltip="Close"
    matTooltipPosition="below"
    (click)="closePreview()"
    >close</span
  >
</div>
