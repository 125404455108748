<div class="dialoghead">
  <h1 class="mat-mdc-dialog-title">Contract</h1>
  <mat-dialog-content>
    <div class="contract_stepwrap" id="contractstep_previd">
      <h2 class="contract_stepheading">
        {{ data.name }}
      </h2>
      <div
        class="contract_stepdescription"
        [innerHTML]="data.description"
      ></div>
    </div>
    <ng-container *ngFor="let step of stepSortedData">
      <div class="contract_stepwrap">
        <h3 class="contract_order" [innerHTML]="step.order"></h3>
        <p class="contract_step" [innerHTML]="step.content"></p>
      </div>
    </ng-container>
  </mat-dialog-content>
  <div
    class="scroll_to_top"
    mat-raised-button
    matTooltip="Scroll To Top"
    matTooltipPosition="below"
    (click)="scroll('contractstep_previd')"
  >
    <img
      src="https://d37pbainmkhx6o.cloudfront.net/Timothy-Desmond-Nest/Home/Timothy_footer_block/icons8-arrow-100.png"
    />
  </div>
</div>
<span
  (click)="closeDialog()"
  mat-button
  mat-dialog-close
  class="close-btn-modal material-icons"
  mat-raised-button
  matTooltip="Close"
  matTooltipPosition="below"
  >close</span
>
