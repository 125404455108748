import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import { WrongPageComponent } from './wrong-page/wrong-page.component';
import { RedirectToNdaComponent } from './redirect-to-nda/redirect-to-nda.component';
import { ContractComponent } from './rep-dashboard/contract/contract.component';
import { ResolveService } from './services/resolve.service';
import { TrainingComponent } from './training/training.component';

const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [AuthGuardService] },
  {
    path: 'admin-dashboard',
    loadChildren: () =>
      import('./admin-dashboard/admin-dashboard.module').then((m) => m.AdminDashboardModule),
      canActivate:[AuthGuardService]
  },
  {
    path: 'contract-sign/:_id',
    component: ContractComponent,
    resolve: { data: ResolveService },
    data: {
      requestcondition: {
        
      },
      endpoint: 'contract/fetch_contract_data',
    }
  },
  {
    path: 'rep-dashboard',
    loadChildren: () =>
      import('./rep-dashboard/rep-dashboard.module').then((m) => m.RepDashboardModule),
      canActivate:[AuthGuardService]

  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./forget-password/forget-password.module').then((m) => m.ForgetPasswordModule)
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./reset-password/reset-password.module').then((m) => m.ResetPasswordModule)
  },
  {
    path: 'my-account',
    loadChildren: () =>
      import('./my-account/my-account.module').then((m) => m.MyAccountModule)
  },
  {
    path: 'NDA',
    component: RedirectToNdaComponent
  },
  {
    path: 'nda',
    component: RedirectToNdaComponent
  },
  {
    path: 'trainings',
    // canActivate:[AuthGuardService],
    component: TrainingComponent,
    resolve: { data: ResolveService },
    data: {
      requestcondition: {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "searchcondition": {
          
        },
        "sort": {
          "type": "asc",
          "field": "priority"
        },
        "project": {},
        "token": ""
      }
      ,
      endpoint: 'training/training-videos-list',
    },
  },
  {
    path: 'presentation-center',
    loadChildren: () =>
      import('./presentation-center/presentation-center.module').then((m) => m.PresentationCenterModule)
  },
  {
    path:'**',component: LoginComponent, canActivate: [AuthGuardService]
  },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
