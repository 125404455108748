import { Component, Inject } from '@angular/core';
import { InjectSetupWrapper } from '@angular/core/testing';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { ApiservicesService } from 'src/app/services/apiservices.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pie-modal',
  templateUrl: './pie-modal.component.html',
  styleUrls: ['./pie-modal.component.css']
})
export class PieModalComponent {

  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id', 'usertype'];
  public tabledatatalist: any = [];
  public updatetable: boolean = false;
  tablename = "users";
  editroute = "";
  updateendpoint = "";
  deleteendpoint = "";
  datacollection: any = "reports/click-list";
  public listprogressBar: any = false;
  public api_url_for_managebanner = environment.api_url
  public cookievalue = this.cookieService.get('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : {}
  searchendpoint = "";
  date_search_endpoint: any = "";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  public selected_campaign_index: any = []
  public reportType: any = null
  public endvalue: any = {}
  public startvalue: any = {}
  public countval: any
  public colorcode: boolean = false
  public flag: boolean = false
  public cookieData: any = {};
  public userName: any = {};
  public clickno: number
  public conversionno: number

  constructor(private apiservice: ApiservicesService, public dialog: MatDialog, public router: Router, public activatedRoute: ActivatedRoute, public cookieService: CookieService, public matSnackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log("countmodal++++++++++", data);
    if (this.cookievalue.userinfo.user_type == 'is_rep') {
      this.clickno = data.value.click_count
      this.conversionno = data.value.conversion_count
    } else {
      this.clickno = data.value.clickCount
      this.conversionno = data.value.conversionCount
    }

  }

  public pieData: any = {
    data: {
      labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
      datasets: [{
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        borderWidth: 1,
        backgroundColor: ["red", "blue", "yellow", "green", "purple", "orange"],

      }],



    },
    options: {

      legend: {
        display: true,
        position: 'bottom'
      },
      title: {
        display: true,
        text: "pie chart",
        //html:"<h2>Pie chart</h2>"

      }
    },
    id: 'pie1'


  }

  ngOnInit() {
    console.log("abcged", this.cookievalue);

    this.fetchReports()

    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      console.log("cookieData", this.cookieData.userinfo);
      this.userName = this.cookieData.userinfo.name

    }

    this.apiservice.getHttpDataPost(
      'reports/click-list-count', {
      "condition": {
        "limit": 10,
        "skip": 0
      },
      "searchcondition": {
      },
      "sort": {
        "type": "desc",
        "field": "created_on"
      },
      "project": {},
      "token": ""
    }
    ).subscribe((response: any) => {

      if (response) {
        // this.clickno = response.clickcount
        // this.conversionno = response.conversioncount
        console.log("abbbbbb", this.clickno, this.conversionno);
        // role data count  save 
      }
    })


  }

  selectedindex(index: any, camp_index: any) {
    console.log("selectedindex hit", index, camp_index);
    // if(this.selected_campaign_index[camp_index]==null)
    this.selected_campaign_index[camp_index] = index;
    console.log(this.selected_campaign_index, 'this.selected_campaign_index')
  }

  searchdata() {
    console.log("changed", this.startvalue, this.endvalue);
    const startunixEpoch = moment(this.startvalue).startOf('day').valueOf();
    const endunixEpoch = moment(this.endvalue).endOf('day').valueOf();
    console.log("unixEpoch", startunixEpoch, endunixEpoch);
    const login_user_details = this.cookieService.get('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : {}


    // moment('Tue Feb 28 2017 00:00:00 GMT+0530 (IST)').unix();

    let dataobj = {
      "condition": {
        "limit": 10,
        "skip": 0
      },
      "searchcondition": {
        created_on: { "$gte": startunixEpoch, "$lte": endunixEpoch },
        opportunities_id: this.data.opportunity_id,
        unique_name: this.data.unique_identifier,
        conversion: false
      },
      "sort": {
        "type": "desc",
        "field": "created_on"
      },
      "token": "",
      "project": {}
    }
    this.apiservice.getHttpDataPost('reports/click-list', dataobj).subscribe((response) => {
      console.log("response login info", response);
      if (response.status == "success") {
        this.tabledatatalist = []
        setTimeout(() => {
          this.tabledatatalist = response.results.res
        }, 50);
        console.log("abcdef========>", this.tabledatatalist);
      }

    })

  }

  fetchReports(val: any = 'click') {
    if (this.reportType != val) {
      const login_user_details = this.cookieService.get('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : {}
      this.colorcode = this.colorcode ? false : true
      let dataobj: any = {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "searchcondition": {

          opportunities_id: this.data.opportunity_id,
          unique_name: { $in: this.data.unique_identifier_all }
        },
        "sort": {
          "type": "desc",
          "field": "created_on"
        },
        "token": "",
        "project": {}
      }
      if (val != 'click') {
        dataobj.searchcondition.conversion = true
      }

      this.apiservice.getHttpDataPost('reports/click-list', dataobj).subscribe((response) => {
        console.log("response login info", response);
        if (response.status == "success") {
          this.tabledatatalist = []
          setTimeout(() => {
            this.tabledatatalist = response.results.res
          }, 50);
          console.log("abcdef========>", this.tabledatatalist);
        }

      })
      this.apiservice.getHttpDataPost(
        'reports/click-list-count', {
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "searchcondition": {},
        "sort": {
          "type": "desc",
          "field": "created_on"
        },
        "project": {},
        "token": ""
      }
      ).subscribe((response: any) => {

        if (response) {
          this.date_search_source_count = response.count;
        }
      })
      this.reportType = val
      this.updatetable = !this.updatetable

    }
  }
}
