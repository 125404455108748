<div class="top-header">
  <div class="left-menu">
    <div class="logo-wrapper">
      <div class="logo-sub-wrapper">
        <img src="https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/home/Beto_Logo.png" alt="" />
      </div>
    </div>
  </div>
  <div class="right-menu">
    <!-- <div class="top-btn homeIcon" matTooltip="Back to Home Page">
      <a [href]="frontendurl"><i class="fa fa-home" aria-hidden="true"></i> </a>
      
    </div> -->
    <div class="top-btn user">
      <i class="fa fa-user-circle-o" aria-hidden="true"></i>
    </div>
    <h3 style="text-transform: capitalize;">{{login_user_details.userinfo.name}}</h3>
    <div class="top-btn" [matMenuTriggerFor]="rightdownmenu">
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </div>

    <mat-menu #rightdownmenu="matMenu">
      <button *ngIf="!forTrainings" mat-menu-item (click)="myAccount()">
        <i class="fa fa-user-circle-o" aria-hidden="true"></i> My Account
      </button>
      <button *ngIf="!forTrainings" mat-menu-item (click)="changePass()">
        <i class="fa fa-unlock-alt" aria-hidden="true"></i> Change Password
      </button>
      <!-- <button
        mat-menu-item
        (click)="
          navigateToPath(
            'astrologer-dashboard/profile/edit/' + cookieData.userinfo._id
          )
        "
      >
        <i class="fa fa-unlock-alt" aria-hidden="true"></i> View Profile
      </button> -->
      <button mat-menu-item (click)="logout()">
        <i class="fa fa-sign-out" aria-hidden="true"></i> Logout
      </button>
    </mat-menu>
  </div>
</div>

<div *ngIf="!forTrainings" class="header_mainwrapper">
  <div class="header_subwrapper">
    <div class="menu-wrapper" [class.showmenu]="classToggled">
      <ul>
        <!-- Dashboard Navigation -->
        <li routerLinkActive="active">
          <a [ngClass]="
              currentPath() == '/admin-dashboard' ||
              currentPath() == '/rep-dashboard' ||
              currentPath() == '/customer-dashboard'
                ? 'active'
                : ''
            " (click)="dashboardPage()">Dashboard</a>
        </li>
        <!-- User List Navigation -->
        <li *ngIf="adminFlag">
          <a [ngClass]="
              currentPath() == '/admin-dashboard/user' ? 'active' : '' ||
              currentPath() == '/rep-dashboard/user-list' ? 'active' : '' 
            " (click)="repPath()">Users</a>
        </li>




        <li>
          <a [matMenuTriggerFor]="Leads" [ngClass]="
              currentPath() == '/admin-dashboard/leads/leads-in-system' || currentPath() == '/admin-dashboard/leads/leads-outside-system' ? 'active' : ''||
              currentPath() == '/rep-dashboard/leads/leads-in-system' || currentPath() == '/rep-dashboard/leads/leads-outside-system' ? 'active' : ''
            ">Leads <i class="fa-solid fa-circle-chevron-down"></i></a>
        </li>

        <mat-menu #Leads="matMenu">
          <button mat-menu-item (click)="
              navigateToPath('admin-dashboard/leads/leads-in-system')
            " (click)="leadsPath('leads-in-system')">
            Leads
          </button>
          <button mat-menu-item (click)="navigateToPath('admin-dashboard/leads/leads-outside-system')"
            (click)="leadsPath('leads-outside-system')">
            Leads Outside System
          </button>
        </mat-menu>


        <!-- <li *ngIf="this.login_user_details.userinfo.user_type === 'is_admin'">
          <a
            [matMenuTriggerFor]="Testimonial"
            [ngClass]="
              currentPath() ==
                '/admin-dashboard/testimonial/request-testimonial' ||
              currentPath() == '/admin-dashboard/testimonial/create-testimonial'
                ? 'active'
                : ''
            "
            >Manage Testimonial
            <i class="fa fa-angle-down" aria-hidden="true"></i
          ></a>
        </li> -->

        <!-- Training Navigations -->
        <!-- <li *ngIf="adminFlag">
          <a class="dropdown-menu-toggle" [matMenuTriggerFor]="Opportunities" [ngClass]="
              currentPath() == '/admin-dashboard/training/training-center' ||
              currentPath() == '/admin-dashboard/training/training-category' ||
              currentPath() == '/admin-dashboard/training/training-lesson' ||
              currentPath() == '/admin-dashboard/training/quiz' ||
              currentPath() == '/admin-dashboard/opportunity/product-category' ||
              currentPath() == '/admin-dashboard/opportunity/product-management'
                ? 'active'
                : ''
            ">Opportunities <i class="fa-solid fa-circle-chevron-down"></i></a>
        </li>
        <mat-menu #Opportunities="matMenu">
          <button mat-menu-item (click)="
              navigateToPath('admin-dashboard/opportunity/product-category')
            ">
            Opportunities Category
          </button>
          <button mat-menu-item (click)="navigateToPath('admin-dashboard/opportunity/product-management')">
            Opportunities Management
          </button>
        </mat-menu> -->

        <li *ngIf="adminFlag">
          <a [ngClass]="
              currentPath() == '/admin-dashboard/miscellaneous/followup' ? 'active' : '' 
            " (click)="
              navigateToPath('/admin-dashboard/miscellaneous/followup')
            ">Follow Ups</a>
        </li>

        <!-- Training Center -->
        <!-- <li>
          <a
            [ngClass]="
              currentPath() == '/astrologer-dashboard/training-center'
                ? 'active'
                : ''
            "
            (click)="navigateToPath('astrologer-dashboard/training-center')"
            >Training Center</a
          >
        </li> -->
        <!-- Astro Tollkit -->
        <!-- <li>
          <a
            [ngClass]="
              currentPath() == '/astrologer-dashboard/horosceop' ? 'active' : ''
            "
            (click)="navigateToPath('astrologer-dashboard/horosceop')"
          >
            Astro Toolkit</a
          >
        </li> -->

        <!-- Testimonial Navigation -->
        <!-- <li *ngIf="adminFlag">
          <a
            [matMenuTriggerFor]="Video"
            [ngClass]="
              currentPath() == '' ||
              currentPath() == '/admin-dashboard/training/training-category' ||
              currentPath() == '/admin-dashboard/video/video-managment' ||
              currentPath() == '/admin-dashboard/video/video-category' 
                ? 'active'
                : ''
            "
            >Video</a
          >
        </li>
   

        <! <li *ngIf="adminFlag">
          <a
            [matMenuTriggerFor]="Files"
            [ngClass]="
              currentPath() == '' ||
              currentPath() == '/admin-dashboard/training/training-category' ||
              currentPath() == '/admin-dashboard/files/files-managment' ||
              currentPath() == '/admin-dashboard/files/files-category' 
                ? 'active'  
                : ''
            "
            >Files</a
          >
        </li> -->


        <li>
          <a [matMenuTriggerFor]="Report" [ngClass]="
              currentPath() == '/rep-dashboard/clickreport' ||
              currentPath() == '/rep-dashboard/conversion-report' ||
              currentPath() == '/admin-dashboard/report/conversion-report' ||
              currentPath() == '/admin-dashboard/training/training-category' ||
              currentPath() == '/admin-dashboard/report/click-report'
                ? 'active'
                : ''
            ">Report <i class="fa-solid fa-circle-chevron-down"></i></a>
        </li>
        <mat-menu #Report="matMenu">
          <button mat-menu-item (click)="conversionReportClick()">
            Conversion Report
          </button>
          <button mat-menu-item (click)=" clickReport()">
            Click Report
          </button>
        </mat-menu>

        <li *ngIf="repFlag">
          <a [ngClass]="
              currentPath() == '/rep-dashboard/calendar-management' ? 'active' : '' 
            " (click)="calendarbuttonClick()">Calendar</a>
        </li>

        <li *ngIf="repFlag" [matMenuTriggerFor]="calender_book">
          <a>Meetings <i class="fa-solid fa-circle-chevron-down"></i></a>
        </li>
        <mat-menu #calender_book="matMenu" style="max-width:290px">
          <button mat-menu-item (click)="choseProduct('nethun')">
            Book a Gameplan Call with Beto Paredes and our Closing team
          </button>
          <button mat-menu-item (click)="choseProduct('beto')">
            Book a Discovery Call For a Potential Client
          </button>
        </mat-menu>

        <li *ngIf="adminFlag">
          <a [ngClass]="
              currentPath() == '/admin-dashboard/campaign' ? 'active' : ''
             
            " (click)="campaignPath()">Campaign</a>
        </li>
        <li *ngIf="!repFlag">
          <a [matMenuTriggerFor]="miscellaneous"
            [ngClass]="
            currentPath() == '' ||
              currentPath() == '/admin-dashboard/miscellaneous/banner' || currentPath() == '/admin-dashboard/opportunity/product-category' ||
              currentPath() == '/admin-dashboard/opportunity/product-management' || currentPath() == '/admin-dashboard/disposition' || currentPath() == '/admin-dashboard/files/files-category' || currentPath() == '/admin-dashboard/files/files-managment' || currentPath() == '/admin-dashboard/video/video-category' || currentPath() == '/admin-dashboard/video/video-managment' || currentPath() == '/admin-dashboard/invoice-list' || currentPath() == '/admin-dashboard/contract-management/contract-list' || currentPath() == '/admin-dashboard/contract-management/sent-contracts-list' || currentPath() == '/admin-dashboard/slug-list' || currentPath() == '/admin-dashboard/email-template-management' || currentPath() == '/admin-dashboard/webinar-management/webinar-list' || currentPath() == '/rep-dashboard/asset-menu' || currentPath() == '/rep-dashboard/training-videos' || currentPath() == '/rep-dashboard/contract-status-list' || currentPath() == '/admin-dashboard/contract-management/request-contracts-list' ? 'active' : '' ">Miscellaneous
            <i class="fa-solid fa-circle-chevron-down"></i></a>
        </li>
        <mat-menu #miscellaneous="matMenu">
          <button *ngIf="adminFlag" mat-menu-item (click)="
              navigateToPath('/admin-dashboard/miscellaneous/banner')
            ">
            Banner Management
          </button>
          <button *ngIf="adminFlag" mat-menu-item (click)="
              navigateToPath('/admin-dashboard/miscellaneous/followup')
            ">
            Follow Ups
          </button>

          <button *ngIf="adminFlag" mat-menu-item [matMenuTriggerFor]="Opportunities" [ngClass]="
          currentPath() == '' ||
          currentPath() == '/admin-dashboard/opportunity/product-category' ||
              currentPath() == '/admin-dashboard/opportunity/product-management' 
            ? 'active'  
            : ''
        ">
            Opportunities
          </button>

          <mat-menu #Opportunities="matMenu">
            <button mat-menu-item (click)="
            navigateToPath('admin-dashboard/opportunity/product-category')
          ">
              Opportunities Category
            </button>
            <button mat-menu-item (click)="navigateToPath('admin-dashboard/opportunity/product-management')">
              Opportunities Management
            </button>
          </mat-menu>

          <button *ngIf="adminFlag" mat-menu-item [matMenuTriggerFor]="Files" [ngClass]="
          currentPath() == '' ||
          currentPath() == '/admin-dashboard/training/training-category' ||
          currentPath() == '/admin-dashboard/files/files-managment' ||
          currentPath() == '/admin-dashboard/files/files-category' 
            ? 'active'  
            : ''
        ">
            Files
          </button>

          <mat-menu #Files="matMenu">
            <button mat-menu-item (click)="
                  navigateToPath('/admin-dashboard/files/files-category')
                ">
              Files Category
            </button>
            <button mat-menu-item (click)="
                  navigateToPath('admin-dashboard/files/files-managment')
                ">
              Files Management
            </button>
          </mat-menu>

          <button *ngIf="adminFlag" mat-menu-item [matMenuTriggerFor]="Video">
            Videos
          </button>

          <mat-menu #Video="matMenu">
            <button mat-menu-item (click)="
                navigateToPath('admin-dashboard/video/video-category')
              ">
              Video Category
            </button>
            <button mat-menu-item (click)="
                navigateToPath('admin-dashboard/video/video-managment')
              ">
              Video Management
            </button>
          </mat-menu>

          <button *ngIf="adminFlag" mat-menu-item
            (click)=" navigateToPath('/admin-dashboard/disposition')">Disposition</button>

          <button *ngIf="adminFlag" mat-menu-item (click)=" navigateToPath('/admin-dashboard/invoice-list')">Invoice
            management</button>

          <button *ngIf="adminFlag" mat-menu-item (click)="
              navigateToPath('/admin-dashboard/contract-management/contract-list')
            ">
            Contract Management
          </button>
          <button *ngIf="adminFlag" mat-menu-item (click)="
              navigateToPath('/admin-dashboard/contract-management/sent-contracts-list')
            ">
            Sent Contracts List
          </button>
          <button *ngIf="adminFlag" mat-menu-item (click)="
              navigateToPath('/admin-dashboard/contract-management/request-contracts-list')
            ">
            Request Contracts List
          </button>
          <button *ngIf="adminFlag" mat-menu-item (click)="
            navigateToPath('/admin-dashboard/slug-list')
          ">
            Slug Management
          </button>
          <button *ngIf="adminFlag" mat-menu-item (click)="
            navigateToPath('/admin-dashboard/email-template-management')
          ">
            Email Template
          </button>
          <!-- <button mat-menu-item (click)="
            navigateToPath('/admin-dashboard/webinar-management/webinar-list')
          ">
           Webinar Management
          </button> -->

          <button *ngIf="repFlag" mat-menu-item (click)="
            navigateToPath('/rep-dashboard/asset-menu')
          ">
            Opportunity Assets
          </button>



        </mat-menu>

        <!-- <mat-menu #Files="matMenu">
         <button mat-menu-item >files-category</button> 
         <button mat-menu-item >files-management</button> 

        </mat-menu> -->

        <!-- Calendar Navigations -->
        <!-- <li>
          <a [matMenuTriggerFor]="Calendar">Calendar Management</a>
        </li>
        <mat-menu #Calendar="matMenu">
          <button
            mat-menu-item
            (click)="
              navigateToPath(
                'astrologer-dashboard/calendar-management/event-list'
              )
            "
          >
            Events
          </button>
          <button
            mat-menu-item
            (click)="
              navigateToPath(
                'astrologer-dashboard/calendar-management/create-event'
              )
            "
          >
            Create Event
          </button>
          <button
            mat-menu-item
            (click)="
              navigateToPath(
                'astrologer-dashboard/calendar-management/available-slots'
              )
            "
          >
            Available Slots
          </button>
        </mat-menu> -->


        <li *ngIf="repFlag">
          <a [ngClass]="currentPath() == '/training-videos' ? 'active' : ''" (click)="navigateToPath('/rep-dashboard/training-videos')">Trainings</a>
        </li>

        <li *ngIf="repFlag">
          <a [ngClass]="currentPath() == '/contract-status-list' ? 'active' : ''" (click)="navigateToPath('/rep-dashboard/contract-status-list')">Contracts</a>
        </li>


        <li *ngIf="adminFlag && environmentany">
          <a [ngClass]="currentPath() == '/presentation-center' ? 'active' : ''" (click)="presentaionPath()">Presentation Center</a>
        </li>

        <li *ngIf="adminFlag">
          <a [ngClass]="currentPath() == '/admin-dashboard/show_rep_wise_booking' ? 'active' : ''" (click)="showbookingRoute()">Booking</a>
        </li>


        <li *ngIf="repFlag">
          <a [ngClass]="currentPath() == '/rep-dashboard/associate' ? 'active' : ''" (click)="gotoAssociate()">Associates</a>
        </li>



      </ul>
    </div>
    <div (click)="toggleNav()" class="toggleBtn">
      <i class="fa fa-bars" aria-hidden="true"></i>
    </div>
  </div>
</div>
<ng-container *ngIf="navbuttonLoader">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-container>