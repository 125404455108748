<div class="preview_modal">
  <h1 class="preview_header">Preview : {{ data.value.name }}</h1>
  <mat-dialog-content>
   <div>
    <p *ngIf="data.value.name" class="preview_data">
      <span><strong>Name:</strong></span>
      <span class="preview_value">{{ data.value.name }}</span>
    </p>
    <p *ngIf="data.value.email" class="preview_data">
      <span><strong>Email:</strong></span>
      <span class="preview_value">{{ data.value.email }}</span>
    </p>
    <p *ngIf="data.value.phone_number" class="preview_data">
      <span><strong>Phone Number:</strong></span>
      <span class="preview_value">{{ data.value.phone_number }}</span>
    </p>
    <p *ngIf="data.value.organisation_name" class="preview_data">
      <span><strong>Organisation Name:</strong></span>
      <span class="preview_value">{{ data.value.organisation_name }}</span>
    </p>
    <p *ngIf="data.value.employee_size" class="preview_data">
      <span><strong>Number of Employees:</strong></span>
      <span class="preview_value">{{ data.value.employee_size }}</span>
    </p>
    <p *ngIf="data.value.annual_revenue" class="preview_data">
      <span><strong>Annual Revenue:</strong></span>
      <span class="preview_value">{{ data.value.annual_revenue }}</span>
    </p>
    <p *ngIf="data.value.location_name" class="preview_data">
      <span><strong>Location Name:</strong></span>
      <span class="preview_value">{{ data.value.location_name }}</span>
    </p>
    <p *ngIf="data.value.address" class="preview_data">
      <span><strong>Address:</strong></span>
      <span class="preview_value">{{ data.value.address }}</span>
    </p>
    <p *ngIf="data.value.secondary_email" class="preview_data">
      <span><strong>Secondary Email:</strong></span>
      <span class="preview_value">{{ data.value.secondary_email }}</span>
    </p>
    <p *ngIf="data.value.secondary_phone" class="preview_data">
      <span><strong>Secondary Phone:</strong></span>
      <span class="preview_value">{{ data.value.secondary_phone }}</span>
    </p>
    <p *ngIf="data.value.url.length > 0" class="preview_data">
      <span><strong>URL:</strong></span>
      <span class="preview_value"><span *ngFor="let url of data.value.url">{{url.url}}, </span></span>
    </p>
    <p *ngIf="data.value.city" class="preview_data">
      <span><strong>City:</strong></span>
      <span class="preview_value">{{ data.value.city }}</span>
    </p>
    <p *ngIf="data.value.state" class="preview_data">
      <span><strong>State:</strong></span>
      <span class="preview_value">{{ data.value.state }}</span>
    </p>
    <p *ngIf="data.value.zip" class="preview_data">
      <span><strong>Zip:</strong></span>
      <span class="preview_value">{{ data.value.zip }}</span>
    </p>
    <p *ngIf="data.value.other_information" class="preview_data">
      <span><strong>Other Information:</strong></span>
      <span class="preview_value"><span *ngFor="let key of getKeys(otherInformation)" class="other_information_inprev">
        <strong>{{key.split("_").join(" ")}}: </strong>{{otherInformation[key] == "null" ? "NA" : otherInformation[key]}}
      </span></span>
    </p>

   </div>
  </mat-dialog-content>
  <span mat-button mat-dialog-close class="close-btn-modal material-icons lead_preview_close" mat-raised-button
    matTooltip="Close" matTooltipPosition="below" (click)="closePreview()">close</span>
</div>