import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private cookieService: CookieService) {}

  public loggedInNavigation(): any {
    // console.log("cookieService", JSON.parse(this.cookieService.get('login_user_details')))
    const userType: any = this.cookieService.get('login_user_details')
      ? JSON.parse(this.cookieService.get('login_user_details'))
      : '';

    console.log(userType, 'userType');

    if (userType?.userinfo?.user_type === 'is_admin') return '/admin-dashboard';
    if (userType?.userinfo?.user_type === 'is_rep') return '/rep-dashboard';

    return null;
  }

  public isAdminAuthenticated(): boolean {
    // console.log("this.cookieService", JSON.parse(this.cookieService.get('login_user_details')))
    const userType: any = this.cookieService.get('login_user_details')
      ? JSON.parse(this.cookieService.get('login_user_details'))
      : '';
    if (userType?.userinfo?.user_type === 'is_admin') return true;
    return false;
  }
  public isRepAuthenticated(): boolean {
    // console.log("this.cookieService", JSON.parse(this.cookieService.get('login_user_details')))
    const userType: any = this.cookieService.get('login_user_details')
      ? JSON.parse(this.cookieService.get('login_user_details'))
      : '';
    if (userType?.userinfo?.user_type === 'is_rep') return true;
    return false;
  }

  public checkTrainingCompletion(): string {
    const userType: any = this.cookieService.get('login_user_details')
      ? JSON.parse(this.cookieService.get('login_user_details'))
      : '';

    const trainingPercentage = userType?.userinfo.percentage;

    if (trainingPercentage != 100) {
      return '/trainings';
    }

    return '/rep-dashboard';
  }

  public updateTrainingPercentage(percentage: any) {
    let userType: any = this.cookieService.get('login_user_details')
      ? JSON.parse(this.cookieService.get('login_user_details'))
      : '';

    if (userType?.userinfo?.user_type === 'is_rep') {
      userType.userinfo.percentage = percentage;

      this.cookieService.set('login_user_details', JSON.stringify(userType));
    }
  }

  // public isRepAuthenticatedcontract(): any {
  //   const userType: any = this.cookieService.get('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')) : ''
  //   if (userType?.userinfo?.is_rep_contract_signed == 0) { return '/rep-dashboard/sign-contract' }
  //   if (userType?.userinfo?.is_rep_contract_signed == 1 && userType?.userinfo?.is_w9_form_signed == 0) { return '/rep-dashboard/w9-form' }

  //   if(userType?.userinfo?.is_rep_contract_signed == 1 && userType?.userinfo?.is_w9_form_signed == 1) { return '/rep-dashboard' }

  // }
}
