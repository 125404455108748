import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titelcase'
})
export class TitelcasePipe implements PipeTransform {

  transform(value: any): any {
    return value.replaceAll('_',' ').toLowerCase().split(' ').map(function (word:string) {
      return (word.charAt(0).toUpperCase() + word.slice(1));}).join(' ');}
}
