<button mat-dialog-close class="close-btn-modal" mat-raised-button matTooltip="Close" matTooltipPosition="below">
    <mat-icon>close</mat-icon>
</button>

<div class="googleaccount_mainwrapper">
    <mat-dialog-content>
        <div class="googleaccount">
            <div class="dialoghead">
                <h1 *ngIf="owner === 'beto'" class="modal-title">Book a Discovery call with Beto Paredes and our Closing team</h1>
                <h1 *ngIf="owner === 'nethun'" class="modal-title">Book a Gameplan Call with Beto Paredes and our Closing team</h1>
            </div>
        </div>

    
            <div class="AddEditBlog taxonomies_formstyle">
                <div class="addEditForm addEditFormbooking choose_products">
                    <!-- <lib-showform [ngClass]="formsubmition_state == true ? 'feather_com' : ''" [formdata]="ChoseProduct" (onFormFieldChange)="listenFormFieldChange($event)"
                        [formfieldrefreshdata]="formfieldrefreshdata" [formfieldrefresh]="formfieldrefresh"></lib-showform> -->

                        <mat-form-field>
                            <mat-label>Products</mat-label>
                            <mat-select (selectionChange)="onSelectChange($event)" [(ngModel)]="selectedProduct" disableRipple [disabled]="desabel_fields">
                            <ng-container *ngFor="let item of productData">
                              <mat-option [value]="item._id">{{item.name}}</mat-option>
                            </ng-container>
                            </mat-select>
                        </mat-form-field>

                          <ng-container *ngIf="productData_loader">
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                          </ng-container>


                          <ng-container *ngIf="open_auto_conplete ">

                          <mat-form-field class="example-full-width">
                            <mat-label>Lead</mat-label>
                            <input type="text" placeholder="Pick one" (keyup)="handleKeyUp($event)" [(ngModel)]="selectedItem" (ngModelChange)="onItemChange($event)"  aria-label="Lead" matInput [matAutocomplete]="auto" [disabled]="desabel_fields">
                            <mat-autocomplete #auto="matAutocomplete" >
                                <ng-container *ngFor="let item of autocompleteData ; trackBy:identify">
                                    <mat-option [value]="item.val">{{item.val}}</mat-option>
                                </ng-container>
                            </mat-autocomplete>
                          </mat-form-field>

                        </ng-container>


                        <div class="discovery_modal_container">
                            <button class="discovery_modal" mat-button (click)="buttonClickUrlHit()">Book Now</button>
                        </div>


                </div>
            </div>
       
    </mat-dialog-content>
</div>