import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiservicesService } from '../services/apiservices.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../services/auth.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css'],
})
export class TrainingComponent implements OnInit {
  constructor(
    public router: Router,
    private authService: AuthService,
    public cookieService: CookieService,
    public activatedRoute: ActivatedRoute,
    private apiService: ApiservicesService,
    public matSnackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  public cookieData: any = this.cookieService.getAll()['login_user_details']
    ? JSON.parse(this.cookieService.getAll()['login_user_details'])
    : {};

  public trainingData: any = [];

  public requiredVideos: any = [];
  public optionalVideos: any = [];

  public trainingPercent: any = {};

  public loader: boolean = false;

  public hideContent: boolean = true;

  activeIndex: string | null = null;

  ngOnInit() {
    if (this.cookieData.userinfo.RequiredPercentage == 100) {
      this.openModal();
    } else {
      this.hideContent = false;
      this.fetchTrainingPercent();
      this.activatedRoute.data.subscribe((response: any) => {
        if (response.data.status == 'success') {
          this.trainingData = response.data.results.res;
          console.log(this.trainingData, '========fdgsgs======>');

          this.requiredVideos = [];
          this.optionalVideos = [];
          this.trainingData.forEach((category: any) => {
            const required = category.category_data.filter(
              (data: any) => data.is_required == 1
            );
            const optional = category.category_data.filter(
              (data: any) => data.is_required == 0
            );
            if (required.length > 0) {
              this.requiredVideos.push({
                category_name: category.category_name,
                category_data: required,
              });
            }
            if (optional.length > 0) {
              this.optionalVideos.push({
                category_name: category.category_name,
                category_data: optional,
              });
            }

            console.log(this.requiredVideos, this.optionalVideos);
          });

          let onProgressFound = this.autoOpenOnProgressVideo(
            this.requiredVideos,
            true
          );

          if (!onProgressFound) {
            this.autoOpenOnProgressVideo(this.optionalVideos, false);
          }
        }
      });
    }
  }

  videoToggle(section: string, i: number, j: number, data: any) {
    if (data.status !== 'onprogress') {
      this.matSnackBar.open(
        'Please complete your ongoing training before proceeding to the next step !!',
        '',
        {
          duration: 5000,
        }
      );
    }

    const combinedIndex = `${section}TrainingBlock_${i}_${j}`;
    this.activeIndex = this.activeIndex == combinedIndex ? null : combinedIndex;
    console.log(combinedIndex, this.activeIndex, 'pppppppppppppppp');

    if (this.activeIndex !== null) {
      this.scrollToBlock(combinedIndex, data.is_required);
    }
  }

  autoOpenOnProgressVideo(videos: any[], isRequired: boolean): boolean {
    const section = isRequired ? 'required' : 'optional';
    for (let i = 0; i < videos.length; i++) {
      const category = videos[i];
      for (let j = 0; j < category.category_data.length; j++) {
        if (category.category_data[j].status === 'onprogress') {
          const combinedIndex = `${section}TrainingBlock_${i}_${j}`;
          console.log(combinedIndex);
          this.activeIndex = combinedIndex;
          this.scrollToBlock(combinedIndex, isRequired ? 1 : 0);
          return true;
        }
      }
    }
    return false;
  }

  scrollToBlock(combinedIndex: string, isRequired: number) {
    console.log(combinedIndex);

    setTimeout(() => {
      const element = document.getElementById(combinedIndex);
      console.log(element);

      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        const offset = 10;

        window.scrollTo({
          top: elementPosition - offset,
          behavior: 'smooth',
        });
      }
    }, 50);
  }

  onVideoEnd(data: any) {
    console.log('Video has ended:', data);
    this.loader = true;

    let requestBody = {
      video_id: data,
      rep_id: this.cookieData.userinfo._id,
    };

    this.apiService
      .getHttpDataPost('training/watch-done', requestBody)
      .subscribe({
        next: (response: any) => {
          if (response.status == 'success') {
            this.matSnackBar.open('Training done Successfully', 'Ok', {
              duration: 3000,
            });
            const requiredTrainings = this.trainingData.reduce(
              (acc: any[], category: any) => {
                return acc.concat(
                  category.category_data.filter(
                    (item: any) => item.is_required == 1
                  )
                );
              },
              []
            );

            const isLastRequiredVideo =
              requiredTrainings.length > 0 &&
              requiredTrainings[requiredTrainings.length - 1]._id == data;
            console.log(isLastRequiredVideo);

            if (isLastRequiredVideo) {
              console.log(isLastRequiredVideo);
              this.cookieData.userinfo.RequiredPercentage = 100;
              this.cookieService.set(
                'login_user_details',
                JSON.stringify(this.cookieData)
              );

              this.loader = false;
              this.openModal();
            } else {
              this.fetchTrainingPercent();
              this.fetchTrainingList();
              this.loader = false;
            }
          } else {
            this.loader = false;
            this.matSnackBar.open('Something went Wrong !!', '', {
              duration: 3000,
            });
          }
        },
        error: (error: any) => {
          this.loader = false;
          this.matSnackBar.open('Something went Wrong !!', '', {
            duration: 3000,
          });
        },
      });
  }

  fetchTrainingPercent() {
    this.loader = true;
    let requestBody = {
      rep_id: this.cookieData.userinfo._id,
    };
    this.apiService
      .getHttpDataPost('training/check-percentage', requestBody)
      .subscribe({
        next: (response: any) => {
          console.log(response);

          if (response.status == 'success') {
            this.trainingPercent = response.results;
            this.loader = false;

            if (response.results.percentage == 100) {
              this.authService.updateTrainingPercentage(100);

              const dialogRef = this.dialog.open(trainingSuccessModal, {
                panelClass: ['custom-modalbox', 'trainingsuccess-modalbox'],
              });

              setTimeout(() => {
                dialogRef.close();
              }, 5000);

              dialogRef.afterClosed().subscribe((result) => {
                this.router.navigateByUrl('/rep-dashboard');
              });
            }
          }
        },
        error: (error: any) => {
          this.loader = false;
        },
      });
  }

  fetchTrainingList() {
    this.loader = true;
    let requestBody = {
      condition: {
        limit: 10,
        skip: 0,
      },
      searchcondition: {
        rep_id: this.cookieData.userinfo._id,
      },
      sort: {
        type: 'asc',
        field: 'priority',
      },
      project: {},
      token: '',
    };

    this.apiService
      .getHttpDataPost('training/training-videos-list', requestBody)
      .subscribe({
        next: (response: any) => {
          if (response.status == 'success') {
            this.trainingData = response.results.res;
            this.loader = false;
            console.log(this.trainingData, '==============>');

            this.requiredVideos = [];
            this.optionalVideos = [];
            this.trainingData.forEach((category: any) => {
              const required = category.category_data.filter(
                (data: any) => data.is_required == 1
              );
              const optional = category.category_data.filter(
                (data: any) => data.is_required == 0
              );
              if (required.length > 0) {
                this.requiredVideos.push({
                  category_name: category.category_name,
                  category_data: required,
                });
              }
              if (optional.length > 0) {
                this.optionalVideos.push({
                  category_name: category.category_name,
                  category_data: optional,
                });
              }
              console.log(this.requiredVideos, this.optionalVideos);
            });

            let foundOnProgress = false;

            this.requiredVideos.forEach((category: any, i: number) => {
              category.category_data.forEach((data: any, j: number) => {
                if (data.status === 'onprogress' && !foundOnProgress) {
                  this.activeIndex = `requiredTrainingBlock_${i}_${j}`;
                  console.log(`Found in required: ${this.activeIndex}`);
                  foundOnProgress = true;
                }
              });
            });

            if (!foundOnProgress) {
              this.optionalVideos.forEach((category: any, i: number) => {
                category.category_data.forEach((data: any, j: number) => {
                  if (data.status === 'onprogress' && !foundOnProgress) {
                    this.activeIndex = `optionalTrainingBlock_${i}_${j}`;
                    console.log(`Found in optional: ${this.activeIndex}`);
                    foundOnProgress = true;
                  }
                });
              });
            }

            if (this.activeIndex) {
              setTimeout(
                () =>
                  this.scrollToBlock(
                    this.activeIndex as string,
                    foundOnProgress ? 1 : 0
                  ),
                0
              );
            }
          } else {
            this.loader = false;
            this.matSnackBar.open('Something went Wrong !!', '', {
              duration: 3000,
            });
          }
        },
        error: (error: any) => {
          this.loader = false;
          this.matSnackBar.open('Something went Wrong !!', '', {
            duration: 3000,
          });
        },
      });
  }

  openModal() {
    const dialogRef = this.dialog.open(trainingOptionModal, {
      panelClass: ['custom-modalbox', 'trainingoption-modalbox'],
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result, 'dsfdfdsfds');

      if (result == 'toDashboard') {
        this.hideContent = false;
      } else {
        this.hideContent = false;
        this.fetchTrainingPercent();
        this.fetchTrainingList();
      }
    });
  }
}

// << --------------- Required Training Success Modal Component ----------------- >>
@Component({
  selector: 'training-option-modal',
  templateUrl: 'training-option-modal.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, SharedModule, CommonModule],
})
export class trainingOptionModal {
  constructor(
    public router: Router,
    private cookieService: CookieService,
    private dialogRef: MatDialogRef<trainingOptionModal>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  goToDashboard(val: any) {
    if (val == 'yes') {
      this.router.navigateByUrl('/rep-dashboard');
      this.dialogRef.close('toDashboard');
    } else {
      this.dialogRef.close();
    }
  }
}

// << --------------- All Training Success Modal Component ----------------- >>

@Component({
  selector: 'training-success-modal',
  templateUrl: 'training-success-modal.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, SharedModule, CommonModule],
})
export class trainingSuccessModal {
  constructor(
    public router: Router,
    private dialogRef: MatDialogRef<trainingSuccessModal>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
