<div class="lifecycle_modalwrap">


        <div class="dialoghead">
            <h1 class="mat-mdc-dialog-title">Attendee add edit modal</h1>
        </div>

          <div class="calender_block bookNowModal"> 
            <p><strong>Booking Date</strong> : 02/12/2024</p> 
            <p><strong>Slot</strong> : 10:30:00 PM - 11:00:00 PM</p> 
            <p><strong>Timezone</strong> : India Standard Time</p> 
          </div>


          <h4>Add Attendee</h4>

        <div class="note_pera">
            <div>
                <p><strong>Note*:</strong> <strong>"Type Your Name"</strong>  & <strong>"Type Your Email"</strong> are mandatory to fill. After filling up, your name with email reflects on the organizer's Google calendar, and the organizer gets notified of your filled details.</p>
            </div>
        </div>

          <div class="form_container">
            <form #myForm="ngForm">
                <div class="form_container_attende_add">
                    <div class="form_container_add_fields" *ngFor="let item of attendee_mail;index as i" >

                        <div class="from_container_inputs">

                            <div class="input_fields">
                                <input name="name" placeholder="Name" [value]="attendee_mail[i].name" (keyup)="handelInputChange($event,i)" >
                                <ng-container *ngIf="submitionState && errorObj[i] && errorObj[i].name"> 
                                    <mat-hint>{{errorObj[i].name}}</mat-hint>
                                </ng-container>
                                  
                            </div>

                            <div class="input_fields">
                                <input name="email" placeholder="Email" [value]="attendee_mail[i].email" (keyup)="handelInputChange($event,i)" >
                                <ng-container *ngIf="submitionState && errorObj[i] && errorObj[i].email"> 
                                    <mat-hint>{{errorObj[i].email}}</mat-hint>
                                </ng-container>
                            </div>

                        </div>

                        <div class="minus_button" *ngIf="i > 0">
                            <p class="minus" (click)="removeAttendee(i)" > - </p>
                        </div>

                    </div>
                    <ng-container *ngIf="loader">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </ng-container>

                    <button class="add_attendee_button"  (click)="addAttendy()"  >Add ATTENDEE</button>

                </div>

                <button class="submit_button" (click)="addAttendySubmitFuntion()">Submit</button>

            </form>
          </div>
</div>
<span  mat-button mat-dialog-close class="close-btn-modal material-icons" mat-raised-button matTooltip="Close" matTooltipPosition="below">close</span>