<div class="dialoghead preview_">
  <h1 class="mat-mdc-dialog-title preview_header">Media Source</h1>
  <mat-dialog-content>
<div class="customer_preview_modal">
  <!-- <button class="close_button" (click)="closeDialog()">X</button> -->
  <!-- <h2 class="customer_preview_modal_heading">Media</h2> -->
  <!-- <h1>Media Source</h1> -->
  <ul class="customer_preview_modal_data">
    <ng-container *ngIf="data.thumbnail?.type === 'image/png'">
      <li class="video_modal">
        <h2 class="customer_preview_modal_heading">Thumbnail</h2>
        <div class="thumble_image_wrppr">
          <img
            src="https://d37pbainmkhx6o.cloudfront.net/{{
              data?.thumbnail?.baseurl
            }}{{ data?.thumbnail?.fileservername }}"
            alt=""
          />
        </div>
      </li>
    </ng-container>

    <li class="video_modal" *ngIf="data.video">
      <h2 class="customer_preview_modal_heading">Video</h2>
      <div>
        <video width="700" height="450" controls>
          <source
            src="https://d37pbainmkhx6o.cloudfront.net/{{
              data?.video?.baseurl
            }}{{ data?.video?.fileservername }}"
            type="video/mp4"/>
        </video>
      </div>
    </li>

    <li *ngIf="data.youtube_video_link">
      <h2 class="customer_preview_modal_heading">Youtube</h2>
      <div>
        <iframe
          [src]="safeURL"
          frameborder="0"
          width="900"
          height="450"
          controls
        ></iframe>
      </div>
    </li>

    <!-- <ng-container *ngIf="data.files?.type.includes('application') ||('text/html')">
      <li *ngIf="data.files"> 
        <h2 class="customer_preview_modal_heading">Files</h2> 
        <div class="files_upload">         
          <iframe class="doc" src="https://docs.google.com/gview?url=http://writing.engr.psu.edu/workbooks/formal_report_template.doc&embedded=true"></iframe>                 
          <P>https://view.officeapps.live.com/op/embed.aspx?src=https://d37pbainmkhx6o.cloudfront.net/{{data?.files?.baseurl}}{{data?.files?.fileservername}}&embedded=true
          </P>
        </div> 
    </li>
    </ng-container> -->

    <ng-container
      *ngIf="data.files?.type.includes('application') || 'text/html' || 'pdf' || 'document'">
      <li class="files_modal" *ngIf="data.files">       
        <h2 class="customer_preview_modal_heading">Files</h2>
        <div class="files_upload">     
          <iframe class="doc" [src]="iframeUrl"> </iframe>
          </div>
      </li>
    </ng-container>
    <!-- <span class="close-btn-modal" (click)="closePreview()">x</span> -->
 
    <i class="close-btn-modal fa-solid fa-x" (click)="closePreview()" mat-raised-button
    matTooltip="Close"
    matTooltipPosition="below"></i>

    <span><strong>Title:</strong>{{ data.title }}</span>
  </ul>

  <!-- <button class="close_button" (click)="closeDialog()">X</button> -->
  <!-- <p>{{data.youtube_video_link}}</p> -->
</div>
</mat-dialog-content>
</div>
