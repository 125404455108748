import { Component } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-redirect-to-nda',
  templateUrl: './redirect-to-nda.component.html',
  styleUrls: ['./redirect-to-nda.component.css'],
})
export class RedirectToNdaComponent {
  ngOnInit() {
    window.location.href = environment.ndaLink;
  }
}
