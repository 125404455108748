import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-preview-components',
  templateUrl: './preview-components.component.html',
  styleUrls: ['./preview-components.component.css']
})
export class PreviewComponentsComponent
{

  public tabledatatalist: any = [];
  public create_on: any
  public updated_on: any
  public safeURL: any
  iframeUrl: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<PreviewComponentsComponent>, private _sanitizer: DomSanitizer)
  {
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${data.youtube_video_link}`);
    this.iframeUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://d37pbainmkhx6o.cloudfront.net/' + data?.files?.baseurl + data?.files?.fileservername)
  }
  ngOnInit()
  {
    // this.create_on=moment(this.data.create_on).format('MMMM Do YYYY, h:mm:ss a');
    // this.updated_on=moment(this.data.updated_on).format('MMMM Do YYYY, h:mm:ss a');
    console.log("data1 preview", this.data);

  }




  closePreview()
  {
    this.dialogRef.close();
  }

}
