
<!-- ////////////////////////////////////////////////////css_start////////////////////////////////////////////////////////////// -->

<!-- ////////////////////////////////////////////////////css_end////////////////////////////////////////////////////////////// -->

<!-- ////////////////////////////////////////////////////html_start////////////////////////////////////////////////////////////// -->
<div class="main_wrp" *ngIf="!sign_flag">
  <!-- <div class="contract_button_wraper">
      <button matTooltip="Download Contract Form" (click)="pdfConverter()">
        <i class="fa fa fa-download" aria-hidden="true"></i></button>
  </div> -->
  <div class="sub_wrp">
    <div class="img_wrp">
      <img
        src="https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/home/Beto_Logo.png"
      />
    </div>
    <div class="text_wrp">
      <form class="form_wrp" name="loginForm" [formGroup]="loginForm">
        <h1>BETO PAREDES LLC Associate Director Program</h1>
        <p>
          This agreement (hereinafter referred to as "Agreement") dated on
          <span>{{ today }}</span>
          <!-- <mat-form-field class="textfirld_wrp">
                        <input matInput [matDatepicker]="picker" name="date" formControlName="date"
                            class="textfirld_subwrp" required [readonly]="filedstatus" >
                        <mat-datepicker-toggle matIconSuffix [for]="picker"
                            [disabled]="filedstatus?true:false"></mat-datepicker-toggle>
                        <mat-datepicker #picker [disabled]="filedstatus?true:false"></mat-datepicker>
                    </mat-form-field> -->
          <!-- <mat-error *ngIf="errors?.date">Date is required</mat-error> -->

          , by and between Beto Paredes LLC (hereinafter referred to as
          "Company"), an Idaho LLC, and
          <strong>
            <mat-form-field class="textfirld_wrp">
              <input
                matInput
                name="rep_name"
                formControlName="rep_name"
                required
                class="textfirld_subwrp"
                (input)="runing($event)"
                [value]= "loginForm.value.rep_name"
              />
            </mat-form-field>
            <mat-error *ngIf="errors?.rep_name">Rep name is required</mat-error>
          </strong>
          (hereinafter referred to as "Associate"), an individual or business
          with their primary location at
          <strong>
            <!-- <form class="form_wrp" name="loginForm" [formGroup]="loginForm"> -->
            <!-- <mat-form-field class="textfirld_wrp">
              <input
                matInput
                name="location"
                formControlName="location"
                required
                class="textfirld_subwrp"
                (keydown)="runing($event)"
                [readonly]="true"
              />
            </mat-form-field> -->
            <span>
            <mat-form-field class="textfirld_wrp">
                <input
                  matInput
                  name="city"
                  formControlName="city"
                  required
                  class="textfirld_subwrp"
                  (keydown)="runing($event)"
                />
              </mat-form-field>
            <mat-error *ngIf="errors?.city">City is required</mat-error>

            </span>,
            <span>
               <mat-form-field class="textfirld_wrp">
                 <input
                   matInput
                   name="state"
                   formControlName="state"
                   required
                   class="textfirld_subwrp"
                   (keydown)="runing($event)"
                 />
               </mat-form-field>
            <mat-error *ngIf="errors?.state">State is required</mat-error>

             </span>,
             <span>
              <mat-form-field class="textfirld_wrp">
                 <input
                   matInput
                   name="zip"
                   formControlName="zip"
                   required
                   class="textfirld_subwrp"
                   (keydown)="runing($event)"
                 />
               </mat-form-field>
            <mat-error *ngIf="errors?.zip">ZIP is required</mat-error>

             </span>,
            <!-- </form> -->
          </strong>
          . Both Company and Associate may collectively be referred to as
          ("Parties").
        </p>
        <h3>1. AGREEMENT</h3>
        <p>
          1.1. Accordingly, in consideration of the mutual covenants contained
          herein, the Parties agree as follows:
        </p>
        <h3>2. RECITALS</h3>
        <p>
          2.1. Whereas, Company is engaged in the business of providing software
          licenses, laboratory services, branding, custom engineering,
          consulting, 3rd party medical devices in ANS and Lab services and
          marketing related services.
        </p>
        <p>
          2.2. Whereas, Associate is an independent entity who seeks to receive
          a Commission ("Commission”) from Company on occasion in exchange for
          making a personal introduction between potential customers and Company
          ("Referral”).
        </p>
        <p>2.3. Whereas, all monies referred to are in USD ("$”).</p>
        <h3>3. INDEPENDENT ASSOCIATE RELATIONSHIP</h3>
        <p>
          3.1. The Parties acknowledge that Associate will perform their
          obligations hereunder as an independent contractor or Associate and
          shall not be an employee of Company. It is also expressly understood
          that Associate's employees and agents, if any, are not Company's
          employees or agents, and have no authority to bind Company by contract
          or otherwise.
        </p>
        <p>
          3.2. Associate agrees they are solely responsible for understanding
          their rights and obligations as an Associate in their geographic area.
        </p>
        <p>
          3.3. Company shall not be liable for taxes, worker's compensation,
          unemployment insurance, employers' liability, employer's FICA, social
          security, withholding tax, or other taxes or withholding for or on
          behalf of Associate or any other person consulted or employed by the
          Associate in performing Services under this Agreement. All such costs
          shall be Associate's responsibility.
        </p>
        <h3>4. REFERRALS AND COMMISSIONS</h3>
        <p>
          4.1. The Parties agree that the Associate may, from time to time, make
          a personal introduction between potential customers and the Company
          ("Referral”) in return for a commission on closed contracts and the
          delivery of costs in the form of contract fees by the client (referred
          to as "Commissions” or "Commission”) pursuant to the terms of this
          Agreement.
        </p>
        <p>
          4.2. Referral contacts ("Referral Contact”, "Referral Contacts”) are
          only eligible for Commissions if the Associate introduces the Referral
          Contact to Company either in person or on the phone with all 3 parties
          present (Company, Associate, and Referral Contact). Referrals must be
          entered by the standard requirements allocated by the company first
          starting with a submission for review, a validation of the lead and
          then a meeting scheduled with one of the discovery managers at Beto
          Paredes, LLC.
        </p>
        <p>
          4.3. Commissions will be paid only after the Company has received
          payment for the sale which commission is to be paid for. Company will
          not be held liable to pay Commissions on any funds that are
          uncollected or refunded.
        </p>
        <p>
          4.4. Commissions will be paid to Associate by Company in the following
          calendar month of the funds received by client by the end of that
          month. Commission payments start on the 15th and will be mailed before
          the 20th of the following month. Direct deposit is available, but
          requires a separate agreement.
        </p>
        <p>
          4.5. Commission amounts and percentages will be set specifically for
          each product or service based on the exact commission rates found in
          this agreement under section 5 "Commission Schedule".
        </p>
        <p>
          4.6. Commissions will not be due or paid for any period in which
          Associate does not have an accurate and up-to-date federal w­9 form on
          file with Company.
        </p>
        <p>
          4.7. The Associate shall be paid Commissions for all Referrals made
          during the Term of this Agreement. Any referral sent to Company during
          the term of this agreement will be considered an exclusive referral of
          the Associate whether this agreement remains in place or not from the
          time a Discovery call is actually held. This is the marker for the
          relationship to be considered exclusive to the Associate. Each
          referral is tied to paying the Associate based on section 5
          "Commission Schedule” for the life of the relationship of the Referral
          to the Company once a contract is signed. If leads fall into
          negligence before a contract signature and lack of follow up by the
          Associate for more than 90 days after discovery, then the company
          reserves the right to pursue business with said contact outside of
          this agreement.
        </p>
        <p>
          4.8. Associate understands and agrees that the Company may have other
          Associates who are referring Referral Contracts to Company and a
          Referral is only eligible for Commission if Associates is the first
          entity to introduce the Referral Contact to Company. Associates also
          agrees that the Company may have established prior relationship with a
          Referral Contact previous to the Referral of the Referral Contact and
          in that case no Commission are required to be paid by Company for the
          Referral. In such cases proof will be provided.
        </p>
        <p>
          4.9. Referral Contract. A ("Referral Contract(s)”) is a successfully
          executed agreement between Client and a Referral Contact who is
          referred to Company by Associate. The term Referral Contract includes
          both the Initial Contract and Successive Contracts which are defined
          below.
        </p>
        <p>
          4.10. Initial Contract. The ("Initial Contract”) is defined as the
          first Referral Contract agreement established with a client who is
          referred to Company by Associate. A new contract with a client will
          only be considered an Initial Contract if it is the first contract in
          which the Company has established with the client. "Successive
          Contracts” are the Referral Contract’s following the Initial Contract
          with a Referral Contact and do not include the Initial Contract.
        </p>
        <h3>5. COMMISSION SCHEDULE</h3>
        <p>
          5.1 All commission schedules may be altered by Company at any time,
          with or without notice, please contact Company for an updated
          commission schedule if one is required. All Contracts closed will
          follow this schedule Commission will be paid out based upon Company’s
          current commission schedule during the establishment of each Referral
          Contact’s contract.
        </p>
        <p>
          5.2 Any commission that closed during the time that this schedule
          remains in place will be paid at this schedule and the permanent
          lifetime residuals listed unaltered by any change to this schedule.
          Changes will only affect new Referral contracts closed after the
          change date.
        </p>
        <p>
          5.3 Commissions may be based on divisions of work to be done for
          Referral Contract. Associates will be given full disclosure of payment
          schedule paid by Referral Contract.
        </p>
        <h3>5.4 Associate Director Commissions</h3>
        <p>Rapid-Response Virtual Platform - Laboratory license</p>
        <p>Cost: $39,000 plus $2 SAAS Charges</p>
        <p>Direct Commission: $4,900.00</p>
        <p>
          Residual SAAS Commission $.25 per SAAS Submission (paid after we
          collect)
        </p>
        <p>$1,900.00 second contract bonus (instead of the full commission)</p>
        <h5>
          All three contract commissions at $3,900x3 belongs to the rep ($13,600
          all in)
        </h5>
        <h5>
          All contracted customers of the Lab go exclusive to the rep on the
          three as well
        </h5>
        <h5>
          Rep must work to close the 3 locations. 90 days to close the first.
        </h5>
        <p>$500.00 closers bonus</p>

        <div class="costing_list">
          <h4>Rapid Response Extendable Labs</h4>
          <p>
            Cost: 3x$15k per location ($45,000) + 20k discount on Rapid-Response
            Virtual Platform - Laboratory license plus $2 SAAS and RALS license
            Renewal at $5000 per year
          </p>
          <p>Direct Commission: $4,900.00</p>
          <p>
            Residual SAAS Commission $.25 per SAAS Submission (paid after we
            collect)
          </p>
          <p>$500.00 closers bonus</p>
        </div>

        <div class="costing_list">
          <h4>Rapid Response Medical Center POL Contract:</h4>
          <p>
            Cost: $29,000 plus $2 SAAS Charges plus $500 per month for Marketing
            for 18 months plus RALS license Renewal at $5000 a year
          </p>
          <p>Direct Commission: $4,900.00</p>
          <p>Residual SAAS Commission $.25 per SAAS Submission</p>
          <p>$500.00 closers bonus</p>
        </div>

        <div class="costing_list">
          <h4>Rapid Response Medical Center - Lab Partner Contract:</h4>
          <p>Cost: $995 per month for Lab Program & Marketing for 18 months</p>
          <p>$500.00 closers bonus</p>
        </div>

        <div class="costing_list">
          <h4>Decision Doc AI</h4>
          <p>Cost: $1,500 yr and $5 per patient submission</p>
          <p>Direct Commission :$495.00</p>
          <p>Residual SAAS Commission: $.50 per SAAS Submission</p>
          <p>$100.00 closers bonus</p>
        </div>

        <div class="costing_list">
          <h4>Decision Docs MX</h4>
          <h5>$1,500 for CMO AD spend</h5>
          <h5>$10.50 for TMFlow preparation fee</h5>
          <h5>
            $10.50 for Patient Acquisition (includes Youtube, Social Advo, Ad
            spend, and Website)
          </h5>
          <h5>$10.50 per call center application</h5>
          <h5>$5 for Decision Doc AI per patient submission fee</h5>
          <p>Direct Commission: $9,995.00</p>
          <p>Residual SAAS Commission: $.50 per SAAS Submission</p>
          <p>$750.00 closers bonus</p>
        </div>

        <div class="costing_list">
          <h4>Practice Presence</h4>
          <p>Cost: $24,950.00</p>
          <p>Commission: $4,900.00</p>
          <p>$500.00 closers bonus</p>
        </div>

        <h3>6. TERM OF AGREEMENT</h3>
        <p>
          6.1. The term of this Agreement (referred to as "Term") shall commence
          as of the effective date hereof and shall continue thereafter until
          Terminated (referred to as "Terminate", "Terminated", "Termination")
          in writing by either party hereto.
        </p>
        <p>
          6.2. Company has the exclusive right to modify this agreement at any
          time, provided Company serves a 30-day Notice to Associate.
        </p>
        <p>
          6.3. The Parties have the right to terminate this agreement by
          providing a 30-day Notice of termination to the other party.
        </p>
        <h3>7. NON­DISCLOSURE</h3>
        <p>
          7.1. The Associate agrees to not disclose any of the details in this
          agreement either implicitly, explicitly, or indirectly to any
          competing entity, employee of Company or subsidiary, contractor of
          Company or subsidiary, and client of Company or subsidiary without
          permission explicitly given by Company. The Associate agrees not to
          disclose details of compensation with any entity without the explicit
          permission of the Company.
        </p>
        <p>
          7.2. Associate agrees not to disclose or otherwise reveal to any third
          party the identities, addresses, telephone numbers, facsimile numbers,
          email address, telex numbers, bank codes, account number, financial
          reference, or any other entities introduces by Company to the
          Associate without the specific written permission of Company.
        </p>
        <p>
          7.3. Confidential Information. The Associate agrees that they shall
          not, directly or indirectly, disclose, disseminate, use for personal
          benefit, lecture or write articles with respect to, or otherwise
          publish "confidential information". For purposes of this Agreement,
          the term "confidential information" means information and know­how
          disclosed to or known by the Associate which relates to the Company or
          any business idea under development or research by the Company or that
          constitutes a corporate opportunity of the Company and which
          information is not generally known in the relevant trade or industry,
          including without limitation, any document, information, website or
          other data requiring passcode or password access, trade secrets,
          proprietary data, customer lists, contractual arrangements, and
          product and service development. "Confidential information" shall also
          include any other document or information (whether of the Company or
          of any supplier or customer of the Company or of any other person with
          which the Company has an agreement with respect to the confidentiality
          of information) labeled "confidential", "proprietary", or words of
          similar import. Upon Termination of the Term hereof, the Associate
          shall return to or leave with the Company, without making or retaining
          copies thereof, all documents, records, notebooks and similar
          repositories containing "confidential information".
        </p>
        <h3>8. NON CIRCUMVENTION</h3>
        <p>
          8.1. Company agrees not to intentionally circumvent Associate and seek
          Associate's Referral Contacts with intention to avoid paying the
          Commissions as established in this Agreement.
        </p>
        <p>
          8.2. The Associate agrees that once a client is introduced to the
          Company and the first Referral Commission is paid, that the new
          referral is now a Client if said Company. Associate agrees not to try
          and sell, promote, enlist or in any way refer other companies to said
          Client besides Company for the listed services that are available in
          the Media of the Company. If at first pass Client does not have any
          intention of using Company, Company may grant Associate from time to
          time the right to refer other solutions and opportunities to referred
          client. This is at the discretion of the Company to do so.
        </p>
        <p>
          8.3. This non circumvention agreement may stand separately or combined
          with this contract and is agreed upon to be in force during the Term
          of this Agreement and for a period no less than 3 years after the Term
          of this Agreement regardless of the status of any other section in
          this Agreement. The only remedy to the violation of this
          non-circumvention agreement as outlined above is a complete forfeiture
          of all forms of compensation obtained, past and future, by Associate
          from any corporation, partnership, proprietorships, trust,
          individuals, or other entities directly or indirectly introduced to
          Associate by Company, subsidiary, or client thereof, or any individual
          associated with Company, subsidiary, or client thereof, without
          specific written permission of the Company.
        </p>
        <h3>9. INDEMNIFICATION</h3>
        <p>
          9.1. Associate shall not be liable for any claim or demand made
          against Company by any third party. Company shall indemnify Associate
          against all claims, liabilities and costs, including reasonable
          attorney fees, of defending any third party claim or suit arising out
          of the use of the software provided under this agreement. However, the
          Associate hereby agrees to indemnify and hold Company harmless from
          any and all liability, damage, loss, cost, expense or other charge
          resulting directly or indirectly from any act or omission of the
          Associate.
        </p>
        <h3>10. STANDARDS AND ETHICS</h3>
        <p>
          10.1. Associate agrees that all advertising and marketing materials
          must receive prior written approval by Company before usage.
        </p>
        <p>
          10.2. Associate will adhere to simple truth and integrity and will not
          engage in any deceptive or misleading sales practices.
        </p>
        <p>
          10.3. Associate will not in any way demean or speak negatively of
          Company, Company's Clients, Company's Potential Clients, Any member of
          Company, Company's Competition, or Associate's Competition.
        </p>
        <p>
          10.4. Associate will maintain the confidentially of information
          provided by any prospect or client of Company and will not reveal any
          such information without the proper consent or exemption of Company.
        </p>
        <h3>11. GOVERNING LAW AND FORUM</h3>
        <p>
          11.1. This Agreement shall be governed, construed and enforced
          exclusively in accordance with the laws of the State of Idaho and the
          laws of the United States of America, without regard to choice of laws
          or conflict of law provisions thereof that would result in the
          application of the laws of a different jurisdiction. Any arbitration
          or litigation between the Parties shall be conducted exclusively in
          Idaho, and the Parties hereby submit to such exclusive jurisdiction
          and venue and agree that venue shall be proper in Idaho. Each Party
          hereby irrevocably waives, to the fullest extent permitted by law, any
          objection that it may have, whether now or in the future, to the
          laying of venue in, or to the jurisdiction of, any and each of such
          courts for the purpose of any such suit, action, proceeding or
          judgment and further waives any claim that any such suit, action
          proceeding or judgment has been brought in an inconvenient forum, and
          each Party hereby submits to such jurisdiction.
        </p>
        <h3>12. ENTIRE AGREEMENT</h3>
        <p>
          12.1. This agreement contains the entire understanding of the Parties
          and supersedes any and all previous verbal and written agreement or
          understandings. There are no other agreements, representations or
          warranties not set forth in this agreement. This agreement will bind,
          and inure to the benefit of, the Parties and their respective
          successor and assigns. Any modification, amendment, or waiver of any
          provision of this agreement may be made only in writing and signed by
          both Parties. The failure by any party to exercise any rights granted
          herein upon the occurrence of any event set forth in this agreement
          shall not constitute a waiver of any such rights upon the occurrence
          of any such event. In the event any provision of this agreement is
          held to be in violation of any law, statue, regulation, ordinance, or
          court order, this agreement shall be deemed modified accordingly and
          to the extent necessary to comply therewith and shall otherwise
          continue full force and effect.
        </p>
        <h3>13. AGREEMENT EXECUTION</h3>
        <p>
          13.1. The Parties hereto agree to all aspects of this Agreement and
          that facsimile/email signatures shall be as effective as if originals.
          This agreement may be executed in several counterparts, each of which
          shall constitute one and the same instrument. Section or paragraph
          headings in the agreement are for convenience of reference only.
        </p>
        <p>
          In witness whereof, the Parties hereto have caused this Agreement to
          be duly executed and entered into as of the date first above written.
        </p>

        <div class="footer_main_wrp">
          <div class="footer_sub_wrp">
            <h3 class="assciate_wrp">Associate:</h3>

            <!-- <p>(Please Print) :<strong>
                               
                            <mat-form-field class="textfirld_wrp">
                                <input matInput name="signature" formControlName="signature" required
                                    class="textfirld_subwrp" (keydown)="runing($event)" [readonly]="filedstatus" />
                            </mat-form-field>
                            <mat-error *ngIf="errors?.signature">Signature is required</mat-error>
                         
                        </strong>
                    </p> -->

            <p>
              Signature :<strong class="signature_wrpper">
                <mat-form-field class="textfirld_wrp signature_txt_wrppr">
                  <input
                    matInput
                    name="signature"
                    formControlName="signature"
                    autocomplete="new-password"                    
                    required
                    [ngClass]="
                      sign_flag ? 'textfirld_subwrp' : 'textfirld_subwrp'
                    "
                    (keydown)="runing($event)"
                    [readonly]="filedstatus"
                  />
                </mat-form-field>
                <mat-error class="contact_error" *ngIf="errors?.signature"
                  >Signature is required</mat-error
                >
              </strong>
            </p>
            <p>
              Full Name: <strong>
                <mat-form-field class="textfirld_wrp">
                  <input
                    matInput
                    name="rep_name"
                    formControlName="rep_name"
                    [value]= "loginForm.value.rep_name"
                    class=""
                    readonly="true"
                    (input)="runing($event)"
                  />
                </mat-form-field>
                <mat-error class="contact_error" *ngIf="errors?.rep_name">Rep name is required</mat-error>
              </strong>
            </p>
            <!-- <p class="value_wrp">Name :
                            <strong>
                              
                                <mat-form-field class="textfirld_wrp">
                                    <input matInput name="name" formControlName="name" class="textfirld_subwrp"
                                        (keydown)="runing($event)" [readonly]="filedstatus" />
                                </mat-form-field>
                                <mat-error *ngIf="errors?.name">Name is required</mat-error>
                           
                            </strong>

                        </p> -->
            <!-- <p class="value_wrp">Email : <strong>
                            
                                <mat-form-field class="textfirld_wrp">
                                    <input matInput name="email" formControlName="email" required autocomplete="off"
                                        class="textfirld_subwrp" (keydown)="runing($event)" [readonly]="filedstatus" />
                                </mat-form-field>
                                <mat-error *ngIf="errors?.email">Email is required</mat-error>
                              
                            </strong>
                        </p> -->
            <!-- <p class="value_wrp">Phone : <strong>
                              
                                <mat-form-field class="textfirld_wrp">
                                    <input matInput name="phone" formControlName="phone" required minlength="5"
                                        class="textfirld_subwrp" (keydown)="runing($event)" [readonly]="filedstatus" />
                                </mat-form-field>
                                <mat-error *ngIf="errors?.phone">Phone is required</mat-error>
                              
                            </strong>
                        </p> -->
            <!-- <p class="value_wrp">Address : <strong>
                             
                                <mat-form-field class="textfirld_wrp">
                                    <input matInput name="address" formControlName="address" required
                                        class="textfirld_subwrp" (keydown)="runing($event)" [readonly]="filedstatus" />
                                </mat-form-field>
                                <mat-error *ngIf="errors?.address">Address is required</mat-error>
                             
                            </strong>
                        </p> -->
            <!-- <p class="value_wrp">City, State & Zip :<strong>

                                <mat-form-field class="textfirld_wrp">
                                    <input matInput name="city" formControlName="city" required class="textfirld_subwrp"
                                        (keydown)="runing($event)" [readonly]="filedstatus" />
                                </mat-form-field>
                                <mat-error *ngIf="errors?.city">City is required</mat-error>

                            </strong>
                        </p> -->

            <p class="value_wrp">
              Date: <strong>{{ today }}</strong>
            </p>
          </div>

          <div class="sign_wrp">
            <div class="sign_wrp_beto">
              <h3>By:</h3>
              <div class="sign_wrp_beto_img">
                <img
                  src="https://all-frontend-assets.s3.amazonaws.com/betoparedes-v3/beto_signature.webp"
                />
              </div>
            </div>
            <p>
              Full Name:<strong>
                <mat-form-field class="textfirld_wrp">
                  <input
                    matInput
                    name="beto_name"
                    formControlName="beto_name"
                    required
                    class=""
                    (input)="runing($event)"
                   
                  />
                </mat-form-field>
                <mat-error *ngIf="errors?.rep_name">Rep name is required</mat-error>
              </strong>
            </p>
            <p>Designation: Owner/Founder</p>
          </div>
          
        </div>
        <button
          [ngClass]="
            contractData ? 'contract_btn_disable' : 'contract_button_wrp'
          "
          (click)="contractSubmit()"
          [disabled]="contractData ? true : false"
        >
          Submit
        </button>
        <mat-progress-bar
          *ngIf="loader"
          mode="indeterminate"
          class="loader_wrp"
        ></mat-progress-bar>
      </form>
    </div>
  </div>
</div>



<!-- -------------------------------------for download--------------------------------- -->

<div  id="contract_form" *ngIf="sign_flag">
  <div class="main_wrp" style=" padding: 30px;" >
    <div class="contract_button_wraper">
        <button class="printPageButton" matTooltip="Download Contract Form" (click)="pdfConverter()">
          <i class="fa fa fa-download" aria-hidden="true"></i></button>
          <button class="printPageButton" matTooltip="Print Contract Form" (click)="clickPrint()">
            <i class="fa fa fa-print" aria-hidden="true"></i></button>
    </div>
    <div class="sub_wrp" style=" border: 3px solid #000; padding-top: 12px;">
      <div class="img_wrp" style="  display: block; justify-content: center; text-align: center;">
        <img style=" width: 540px; margin-bottom: 10px;"
          src="https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/home/Beto_Logo.png"
        />
      </div>
      <div class="text_wrp" style=" padding: 20px;">
        <form class="form_wrp" name="loginForm" [formGroup]="loginForm">
          <h1 >BETO PAREDES LLC Associate Director Program</h1>
          <p>
            This agreement (hereinafter referred to as "Agreement") dated on
            <span>{{ today }}</span>
            <!-- <mat-form-field class="textfirld_wrp">
                          <input matInput [matDatepicker]="picker" name="date" formControlName="date"
                              class="textfirld_subwrp" required [readonly]="filedstatus" >
                          <mat-datepicker-toggle matIconSuffix [for]="picker"
                              [disabled]="filedstatus?true:false"></mat-datepicker-toggle>
                          <mat-datepicker #picker [disabled]="filedstatus?true:false"></mat-datepicker>
                      </mat-form-field> -->
            <!-- <mat-error *ngIf="errors?.date">Date is required</mat-error> -->
  
            , by and between Beto Paredes LLC (hereinafter referred to as
            "Company"), an Idaho LLC, and
            <strong>
              <!-- <mat-form-field class="textfirld_wrp" disabled="control">
                <input
                  matInput
                  name="rep_name"
                  formControlName="rep_name"
                  required
                  class="textfirld_subwrp"
                  (keydown)="runing($event)"
                  [readonly]="true"
                />
              </mat-form-field>
              <mat-error *ngIf="errors?.rep_name">Rep name is required</mat-error> -->
              <span>{{this.contractData?.rep_name}}</span>
            </strong>
            (hereinafter referred to as "Associate"), an individual or business
            with their primary location at
            <strong>
              <!-- <form class="form_wrp" name="loginForm" [formGroup]="loginForm"> -->
              <!-- <mat-form-field class="textfirld_wrp">
                <input
                  matInput
                  name="location"
                  formControlName="location"
                  required
                  class="textfirld_subwrp"
                  (keydown)="runing($event)"
                  [readonly]="true"
                />
              </mat-form-field>
              <mat-error *ngIf="errors?.location">Location is required</mat-error> -->
              <!-- </form> -->
              <!-- <span>{{this.contractData?.location}}</span> -->
              <span>{{this.contractData?.city}}</span>,
              <span>{{this.contractData?.state}}</span>,
              <span>{{this.contractData?.zip}}</span>
            </strong>
            . Both Company and Associate may collectively be referred to as
            ("Parties").
          </p>
          <h3>1. AGREEMENT</h3>
          <p>
            1.1. Accordingly, in consideration of the mutual covenants contained
            herein, the Parties agree as follows:
          </p>
          <h3>2. RECITALS</h3>
          <p>
            2.1. Whereas, Company is engaged in the business of providing software
            licenses, laboratory services, branding, custom engineering,
            consulting, 3rd party medical devices in ANS and Lab services and
            marketing related services.
          </p>
          <p>
            2.2. Whereas, Associate is an independent entity who seeks to receive
            a Commission ("Commission”) from Company on occasion in exchange for
            making a personal introduction between potential customers and Company
            ("Referral”).
          </p>
          <p>2.3. Whereas, all monies referred to are in USD ("$”).</p>
          <h3>3. INDEPENDENT ASSOCIATE RELATIONSHIP</h3>
          <p>
            3.1. The Parties acknowledge that Associate will perform their
            obligations hereunder as an independent contractor or Associate and
            shall not be an employee of Company. It is also expressly understood
            that Associate's employees and agents, if any, are not Company's
            employees or agents, and have no authority to bind Company by contract
            or otherwise.
          </p>
          <p>
            3.2. Associate agrees they are solely responsible for understanding
            their rights and obligations as an Associate in their geographic area.
          </p>
          <p>
            3.3. Company shall not be liable for taxes, worker's compensation,
            unemployment insurance, employers' liability, employer's FICA, social
            security, withholding tax, or other taxes or withholding for or on
            behalf of Associate or any other person consulted or employed by the
            Associate in performing Services under this Agreement. All such costs
            shall be Associate's responsibility.
          </p>
          <h3>4. REFERRALS AND COMMISSIONS</h3>
          <p>
            4.1. The Parties agree that the Associate may, from time to time, make
            a personal introduction between potential customers and the Company
            ("Referral”) in return for a commission on closed contracts and the
            delivery of costs in the form of contract fees by the client (referred
            to as "Commissions” or "Commission”) pursuant to the terms of this
            Agreement.
          </p>
          <p>
            4.2. Referral contacts ("Referral Contact”, "Referral Contacts”) are
            only eligible for Commissions if the Associate introduces the Referral
            Contact to Company either in person or on the phone with all 3 parties
            present (Company, Associate, and Referral Contact). Referrals must be
            entered by the standard requirements allocated by the company first
            starting with a submission for review, a validation of the lead and
            then a meeting scheduled with one of the discovery managers at Beto
            Paredes, LLC.
          </p>
          <p>
            4.3. Commissions will be paid only after the Company has received
            payment for the sale which commission is to be paid for. Company will
            not be held liable to pay Commissions on any funds that are
            uncollected or refunded.
          </p>
          <p>
            4.4. Commissions will be paid to Associate by Company in the following
            calendar month of the funds received by client by the end of that
            month. Commission payments start on the 15th and will be mailed before
            the 20th of the following month. Direct deposit is available, but
            requires a separate agreement.
          </p>
          <p>
            4.5. Commission amounts and percentages will be set specifically for
            each product or service based on the exact commission rates found in
            this agreement under section 5 "Commission Schedule".
          </p>
          <p>
            4.6. Commissions will not be due or paid for any period in which
            Associate does not have an accurate and up-to-date federal w­9 form on
            file with Company.
          </p>
          <p>
            4.7. The Associate shall be paid Commissions for all Referrals made
            during the Term of this Agreement. Any referral sent to Company during
            the term of this agreement will be considered an exclusive referral of
            the Associate whether this agreement remains in place or not from the
            time a Discovery call is actually held. This is the marker for the
            relationship to be considered exclusive to the Associate. Each
            referral is tied to paying the Associate based on section 5
            "Commission Schedule” for the life of the relationship of the Referral
            to the Company once a contract is signed. If leads fall into
            negligence before a contract signature and lack of follow up by the
            Associate for more than 90 days after discovery, then the company
            reserves the right to pursue business with said contact outside of
            this agreement.
          </p>
          <p>
            4.8. Associate understands and agrees that the Company may have other
            Associates who are referring Referral Contracts to Company and a
            Referral is only eligible for Commission if Associates is the first
            entity to introduce the Referral Contact to Company. Associates also
            agrees that the Company may have established prior relationship with a
            Referral Contact previous to the Referral of the Referral Contact and
            in that case no Commission are required to be paid by Company for the
            Referral. In such cases proof will be provided.
          </p>
          <p>
            4.9. Referral Contract. A ("Referral Contract(s)”) is a successfully
            executed agreement between Client and a Referral Contact who is
            referred to Company by Associate. The term Referral Contract includes
            both the Initial Contract and Successive Contracts which are defined
            below.
          </p>
          <p>
            4.10. Initial Contract. The ("Initial Contract”) is defined as the
            first Referral Contract agreement established with a client who is
            referred to Company by Associate. A new contract with a client will
            only be considered an Initial Contract if it is the first contract in
            which the Company has established with the client. "Successive
            Contracts” are the Referral Contract’s following the Initial Contract
            with a Referral Contact and do not include the Initial Contract.
          </p>
          <h3>5. COMMISSION SCHEDULE</h3>
          <p>
            5.1 All commission schedules may be altered by Company at any time,
            with or without notice, please contact Company for an updated
            commission schedule if one is required. All Contracts closed will
            follow this schedule Commission will be paid out based upon Company’s
            current commission schedule during the establishment of each Referral
            Contact’s contract.
          </p>
          <p>
            5.2 Any commission that closed during the time that this schedule
            remains in place will be paid at this schedule and the permanent
            lifetime residuals listed unaltered by any change to this schedule.
            Changes will only affect new Referral contracts closed after the
            change date.
          </p>
          <p>
            5.3 Commissions may be based on divisions of work to be done for
            Referral Contract. Associates will be given full disclosure of payment
            schedule paid by Referral Contract.
          </p>
          <h3>5.4 Associate Director Commissions</h3>
          <p>Rapid-Response Virtual Platform - Laboratory license</p>
          <p>Cost: $39,000 plus $2 SAAS Charges</p>
          <p>Direct Commission: $4,900.00</p>
          <p>
            Residual SAAS Commission $.25 per SAAS Submission (paid after we
            collect)
          </p>
          <p>$1,900.00 second contract bonus (instead of the full commission)</p>
          <h5>
            All three contract commissions at $3,900x3 belongs to the rep ($13,600
            all in)
          </h5>
          <h5>
            All contracted customers of the Lab go exclusive to the rep on the
            three as well
          </h5>
          <h5>
            Rep must work to close the 3 locations. 90 days to close the first.
          </h5>
          <p>$500.00 closers bonus</p>
  
          <div class="costing_list">
            <h4>Rapid Response Extendable Labs</h4>
            <p>
              Cost: 3x$15k per location ($45,000) + 20k discount on Rapid-Response
              Virtual Platform - Laboratory license plus $2 SAAS and RALS license
              Renewal at $5000 per year
            </p>
            <p>Direct Commission: $4,900.00</p>
            <p>
              Residual SAAS Commission $.25 per SAAS Submission (paid after we
              collect)
            </p>
            <p>$500.00 closers bonus</p>
          </div>
  
          <div class="costing_list">
            <h4>Rapid Response Medical Center POL Contract:</h4>
            <p>
              Cost: $29,000 plus $2 SAAS Charges plus $500 per month for Marketing
              for 18 months plus RALS license Renewal at $5000 a year
            </p>
            <p>Direct Commission: $4,900.00</p>
            <p>Residual SAAS Commission $.25 per SAAS Submission</p>
            <p>$500.00 closers bonus</p>
          </div>
  
          <div class="costing_list">
            <h4>Rapid Response Medical Center - Lab Partner Contract:</h4>
            <p>Cost: $995 per month for Lab Program & Marketing for 18 months</p>
            <p>$500.00 closers bonus</p>
          </div>
  
          <div class="costing_list">
            <h4>Decision Doc AI</h4>
            <p>Cost: $1,500 yr and $5 per patient submission</p>
            <p>Direct Commission :$495.00</p>
            <p>Residual SAAS Commission: $.50 per SAAS Submission</p>
            <p>$100.00 closers bonus</p>
          </div>
  
          <div class="costing_list">
            <h4>Decision Docs MX</h4>
            <h5>$1,500 for CMO AD spend</h5>
            <h5>$10.50 for TMFlow preparation fee</h5>
            <h5>
              $10.50 for Patient Acquisition (includes Youtube, Social Advo, Ad
              spend, and Website)
            </h5>
            <h5>$10.50 per call center application</h5>
            <h5>$5 for Decision Doc AI per patient submission fee</h5>
            <p>Direct Commission: $9,995.00</p>
            <p>Residual SAAS Commission: $.50 per SAAS Submission</p>
            <p>$750.00 closers bonus</p>
          </div>
  
          <div class="costing_list">
            <h4>Practice Presence</h4>
            <p>Cost: $24,950.00</p>
            <p>Commission: $4,900.00</p>
            <p>$500.00 closers bonus</p>
          </div>
  
          <h3>6. TERM OF AGREEMENT</h3>
          <p>
            6.1. The term of this Agreement (referred to as "Term") shall commence
            as of the effective date hereof and shall continue thereafter until
            Terminated (referred to as "Terminate", "Terminated", "Termination")
            in writing by either party hereto.
          </p>
          <p>
            6.2. Company has the exclusive right to modify this agreement at any
            time, provided Company serves a 30-day Notice to Associate.
          </p>
          <p>
            6.3. The Parties have the right to terminate this agreement by
            providing a 30-day Notice of termination to the other party.
          </p>
          <h3>7. NON­DISCLOSURE</h3>
          <p>
            7.1. The Associate agrees to not disclose any of the details in this
            agreement either implicitly, explicitly, or indirectly to any
            competing entity, employee of Company or subsidiary, contractor of
            Company or subsidiary, and client of Company or subsidiary without
            permission explicitly given by Company. The Associate agrees not to
            disclose details of compensation with any entity without the explicit
            permission of the Company.
          </p>
          <p>
            7.2. Associate agrees not to disclose or otherwise reveal to any third
            party the identities, addresses, telephone numbers, facsimile numbers,
            email address, telex numbers, bank codes, account number, financial
            reference, or any other entities introduces by Company to the
            Associate without the specific written permission of Company.
          </p>
          <p>
            7.3. Confidential Information. The Associate agrees that they shall
            not, directly or indirectly, disclose, disseminate, use for personal
            benefit, lecture or write articles with respect to, or otherwise
            publish "confidential information". For purposes of this Agreement,
            the term "confidential information" means information and know­how
            disclosed to or known by the Associate which relates to the Company or
            any business idea under development or research by the Company or that
            constitutes a corporate opportunity of the Company and which
            information is not generally known in the relevant trade or industry,
            including without limitation, any document, information, website or
            other data requiring passcode or password access, trade secrets,
            proprietary data, customer lists, contractual arrangements, and
            product and service development. "Confidential information" shall also
            include any other document or information (whether of the Company or
            of any supplier or customer of the Company or of any other person with
            which the Company has an agreement with respect to the confidentiality
            of information) labeled "confidential", "proprietary", or words of
            similar import. Upon Termination of the Term hereof, the Associate
            shall return to or leave with the Company, without making or retaining
            copies thereof, all documents, records, notebooks and similar
            repositories containing "confidential information".
          </p>
          <h3>8. NON CIRCUMVENTION</h3>
          <p>
            8.1. Company agrees not to intentionally circumvent Associate and seek
            Associate's Referral Contacts with intention to avoid paying the
            Commissions as established in this Agreement.
          </p>
          <p>
            8.2. The Associate agrees that once a client is introduced to the
            Company and the first Referral Commission is paid, that the new
            referral is now a Client if said Company. Associate agrees not to try
            and sell, promote, enlist or in any way refer other companies to said
            Client besides Company for the listed services that are available in
            the Media of the Company. If at first pass Client does not have any
            intention of using Company, Company may grant Associate from time to
            time the right to refer other solutions and opportunities to referred
            client. This is at the discretion of the Company to do so.
          </p>
          <p>
            8.3. This non circumvention agreement may stand separately or combined
            with this contract and is agreed upon to be in force during the Term
            of this Agreement and for a period no less than 3 years after the Term
            of this Agreement regardless of the status of any other section in
            this Agreement. The only remedy to the violation of this
            non-circumvention agreement as outlined above is a complete forfeiture
            of all forms of compensation obtained, past and future, by Associate
            from any corporation, partnership, proprietorships, trust,
            individuals, or other entities directly or indirectly introduced to
            Associate by Company, subsidiary, or client thereof, or any individual
            associated with Company, subsidiary, or client thereof, without
            specific written permission of the Company.
          </p>
          <h3>9. INDEMNIFICATION</h3>
          <p>
            9.1. Associate shall not be liable for any claim or demand made
            against Company by any third party. Company shall indemnify Associate
            against all claims, liabilities and costs, including reasonable
            attorney fees, of defending any third party claim or suit arising out
            of the use of the software provided under this agreement. However, the
            Associate hereby agrees to indemnify and hold Company harmless from
            any and all liability, damage, loss, cost, expense or other charge
            resulting directly or indirectly from any act or omission of the
            Associate.
          </p>
          <h3>10. STANDARDS AND ETHICS</h3>
          <p>
            10.1. Associate agrees that all advertising and marketing materials
            must receive prior written approval by Company before usage.
          </p>
          <p>
            10.2. Associate will adhere to simple truth and integrity and will not
            engage in any deceptive or misleading sales practices.
          </p>
          <p>
            10.3. Associate will not in any way demean or speak negatively of
            Company, Company's Clients, Company's Potential Clients, Any member of
            Company, Company's Competition, or Associate's Competition.
          </p>
          <p>
            10.4. Associate will maintain the confidentially of information
            provided by any prospect or client of Company and will not reveal any
            such information without the proper consent or exemption of Company.
          </p>
          <h3>11. GOVERNING LAW AND FORUM</h3>
          <p>
            11.1. This Agreement shall be governed, construed and enforced
            exclusively in accordance with the laws of the State of Idaho and the
            laws of the United States of America, without regard to choice of laws
            or conflict of law provisions thereof that would result in the
            application of the laws of a different jurisdiction. Any arbitration
            or litigation between the Parties shall be conducted exclusively in
            Idaho, and the Parties hereby submit to such exclusive jurisdiction
            and venue and agree that venue shall be proper in Idaho. Each Party
            hereby irrevocably waives, to the fullest extent permitted by law, any
            objection that it may have, whether now or in the future, to the
            laying of venue in, or to the jurisdiction of, any and each of such
            courts for the purpose of any such suit, action, proceeding or
            judgment and further waives any claim that any such suit, action
            proceeding or judgment has been brought in an inconvenient forum, and
            each Party hereby submits to such jurisdiction.
          </p>
          <h3>12. ENTIRE AGREEMENT</h3>
          <p>
            12.1. This agreement contains the entire understanding of the Parties
            and supersedes any and all previous verbal and written agreement or
            understandings. There are no other agreements, representations or
            warranties not set forth in this agreement. This agreement will bind,
            and inure to the benefit of, the Parties and their respective
            successor and assigns. Any modification, amendment, or waiver of any
            provision of this agreement may be made only in writing and signed by
            both Parties. The failure by any party to exercise any rights granted
            herein upon the occurrence of any event set forth in this agreement
            shall not constitute a waiver of any such rights upon the occurrence
            of any such event. In the event any provision of this agreement is
            held to be in violation of any law, statue, regulation, ordinance, or
            court order, this agreement shall be deemed modified accordingly and
            to the extent necessary to comply therewith and shall otherwise
            continue full force and effect.
          </p>
          <h3>13. AGREEMENT EXECUTION</h3>
          <p>
            13.1. The Parties hereto agree to all aspects of this Agreement and
            that facsimile/email signatures shall be as effective as if originals.
            This agreement may be executed in several counterparts, each of which
            shall constitute one and the same instrument. Section or paragraph
            headings in the agreement are for convenience of reference only.
          </p>
          <p>
            In witness whereof, the Parties hereto have caused this Agreement to
            be duly executed and entered into as of the date first above written.
          </p>
  
          <div class="footer_main_wrp" style=" display: flex; justify-content: space-between;">
            <div class="footer_sub_wrp">
              <h3 class="assciate_wrp">Associate:</h3>
  
              <!-- <p>(Please Print) :<strong>
                                 
                              <mat-form-field class="textfirld_wrp">
                                  <input matInput name="signature" formControlName="signature" required
                                      class="textfirld_subwrp" (keydown)="runing($event)" [readonly]="filedstatus" />
                              </mat-form-field>
                              <mat-error *ngIf="errors?.signature">Signature is required</mat-error>
                           
                          </strong>
                      </p> -->
  
              <p>
                Signature :<strong class="signature_wrpper">
                  <!-- <mat-form-field class="textfirld_wrp signature_txt_wrppr">
                    <input
                      matInput
                      name="signature"
                      formControlName="signature"
                      required
                      [ngClass]="
                        sign_flag ? 'textfirld_subwrp' : 'textfirld_subwrp'
                      "
                      (keydown)="runing($event)"
                      [readonly]="filedstatus"
                    />
                  </mat-form-field> -->
                  <span class="textfirld_subwrp">{{this.contractData?.signature}}</span>
                  
                </strong>
              </p>
              <p>Full Name: {{this.contractData?.rep_name}}</p>
              <!-- <p class="value_wrp">Name :
                              <strong>
                                
                                  <mat-form-field class="textfirld_wrp">
                                      <input matInput name="name" formControlName="name" class="textfirld_subwrp"
                                          (keydown)="runing($event)" [readonly]="filedstatus" />
                                  </mat-form-field>
                                  <mat-error *ngIf="errors?.name">Name is required</mat-error>
                             
                              </strong>
  
                          </p> -->
              <!-- <p class="value_wrp">Email : <strong>
                              
                                  <mat-form-field class="textfirld_wrp">
                                      <input matInput name="email" formControlName="email" required autocomplete="off"
                                          class="textfirld_subwrp" (keydown)="runing($event)" [readonly]="filedstatus" />
                                  </mat-form-field>
                                  <mat-error *ngIf="errors?.email">Email is required</mat-error>
                                
                              </strong>
                          </p> -->
              <!-- <p class="value_wrp">Phone : <strong>
                                
                                  <mat-form-field class="textfirld_wrp">
                                      <input matInput name="phone" formControlName="phone" required minlength="5"
                                          class="textfirld_subwrp" (keydown)="runing($event)" [readonly]="filedstatus" />
                                  </mat-form-field>
                                  <mat-error *ngIf="errors?.phone">Phone is required</mat-error>
                                
                              </strong>
                          </p> -->
              <!-- <p class="value_wrp">Address : <strong>
                               
                                  <mat-form-field class="textfirld_wrp">
                                      <input matInput name="address" formControlName="address" required
                                          class="textfirld_subwrp" (keydown)="runing($event)" [readonly]="filedstatus" />
                                  </mat-form-field>
                                  <mat-error *ngIf="errors?.address">Address is required</mat-error>
                               
                              </strong>
                          </p> -->
              <!-- <p class="value_wrp">City, State & Zip :<strong>
  
                                  <mat-form-field class="textfirld_wrp">
                                      <input matInput name="city" formControlName="city" required class="textfirld_subwrp"
                                          (keydown)="runing($event)" [readonly]="filedstatus" />
                                  </mat-form-field>
                                  <mat-error *ngIf="errors?.city">City is required</mat-error>
  
                              </strong>
                          </p> -->
  
              <p class="value_wrp">
                Date: <strong>{{ today }}</strong>
              </p>
            </div>
  
            <div class="sign_wrp" >
              <div class="sign_wrp_beto" style="display: flex; align-items: center;">
                <h3>By:</h3>
                <div class="sign_wrp_beto_img" style=" width: 200px; height: 100px"> 
                  <img style="width: 100%; height: 100%; display: block;"
                    src="https://all-frontend-assets.s3.amazonaws.com/betoparedes-v3/beto_signature.webp"
                  />
                </div>
              </div>
              <!-- <p>Full Name: {{this.contractData?.rep_name}}Beto Paredes</p> -->
              <p>Full Name: Beto Paredes</p>

              <p>Designation: Owner/Founder</p>
            </div>
          </div>
         
         
        </form>
      </div>
    </div>
  </div>
</div>


<!-- -------------------------------------for download--------------------------------- -->

