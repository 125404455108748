<app-header></app-header>
<div class="mainwrapper">
  <div class="new-container">
    <div class="subwrapper">
      <app-last-login-info></app-last-login-info>
      <div class="sub-title wrapper">
        <h2>Contract List</h2>
        <button
          mat-button
          class="add_btn"
          (click)="
            router.navigate([
              'admin-dashboard/contract-management/add-contract'
            ])
          "
        >
          Add Contract
        </button>
      </div>
      <div class="wrapper">
        <div class="list-style1">
          <div
            class="taxonomies_liststyle"
            [ngClass]="listprogressBar == true ? 'hide' : 'show'"
          >
            <mat-progress-bar
              *ngIf="loader"
              mode="indeterminate"
            ></mat-progress-bar>
            <lib-listing
              class="adminlisttable_v1"
              *ngIf="tabledatatalist != null && tabledatatalist.length > 0"
              [datasource]="tabledatatalist"
              [skip]="tabledata_header_skip"
              [modify_header_array]="modify_header_array"
              [apiurl]="api_url_for_managebanner"
              [deleteendpoint]="deleteendpoint"
              [updateendpoint]="updateendpoint"
              [date_search_source]="date_search_source"
              [date_search_endpoint]="date_search_endpoint"
              [sourcedata]="tablename"
              [updatetable]="updatetable"
              [editroute]="editroute"
              [statusarr]="statusarray"
              [detail_skip_array]="tabledata_detail_skip"
              [jwttoken]="jwttokenformanagebanner"
              [search_settings]="search_settings"
              [searchendpoint]="searchendpoint"
              [sortdata]="sortdata"
              [datacollection]="datacollection"
              [date_search_source_count]="date_search_source_count"
              [libdata]="libdata"
              [limitcond]="limitcond"
              [customlistenbutton]="customlistenbutton"
              [updatetable]="taxonomy_updatetable"
              (onLiblistingButtonChange)="onLiblistingButtonChange($event)"
              (onLiblistingChange)="listenLiblistingChange($event)"
            >
            </lib-listing>
            <div *ngIf="!(tabledatatalist.length > 0)" class="noFoundTextinner">
              <span>Oops !</span>
              <p>NO Record Found</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
