import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-wrong-page',
  templateUrl: './wrong-page.component.html',
  styleUrls: ['./wrong-page.component.css']
})
export class WrongPageComponent {

  userData :any ={}

  constructor(private cookieService: CookieService,public router: Router) {
    
   }


  ngOnInit() {
    this.userData = JSON.parse(this.cookieService.get('login_user_details'))?JSON.parse(this.cookieService.get('login_user_details')):undefined;
    console.log(this.userData);

    if(this.userData?.userinfo?.user_type === 'is_admin'){
      this.router.navigateByUrl('/admin-dashboard');
    }
    if(this.userData?.userinfo?.user_type === 'is_rep'){
      this.router.navigateByUrl('/rep-dashboard');
    }
    if(!this.userData){
      this.router.navigateByUrl('/');
    }
    
  }





}
