import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogData } from 'listing-angular15';
import { CookieService } from 'ngx-cookie-service';
import { ApiservicesService } from 'src/app/services/apiservices.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { AttendComponent } from '../../attend/attend.component';
import { PreviewComponent } from 'src/app/Common-components/preview/preview.component';
import { RescheduleFollowupComponent } from '../../reschedule-followup/reschedule-followup.component';

@Component({
  selector: 'app-followup-details',
  templateUrl: './followup-details.component.html',
  styleUrls: ['./followup-details.component.css'],
})
export class FollowupDetailsComponent {
  constructor(
    public router: Router,
    public cookieService: CookieService,
    public activatedRoute: ActivatedRoute,
    private apiservice: ApiservicesService,
    public dialog: MatDialog
  ) {}
  public progressLoader: boolean = false;
  public tabledatatalist: any = [];
  public header = 'Add Package';
  public userName: any = {};
  public lastLogin: any = {};
  datacollection: any = 'followup/follow-list';
  deleteendpoint = 'followup/followup-delete';
  date_search_source_count: any = 0;
  tabledata_header_skip: any = ['_id'];
  public cookieData: any = {};
  public api_url_for_managebanner = environment.api_url;
  public listprogressBar: any = false;
  public listdataFlag: any = false;
  public paramsId: any = {};
  public now: Date = new Date();
  public currentUnixTimestamp = moment().valueOf();
  public searchCondData: any;
  public newressBar: any = false;
  public editButtonStatus = true;
  public attendButtonStatus = true;
  public loader: boolean = false;

  public buttonFlag: any = 'upcoming';

  modify_header_array: any = {
    addedby_name: 'Added By',
    addedfor_name: 'Added For',
    date: 'Date',
    start_time: 'Time',
    created_on: 'Created On',
    // lead_id: 'Lead ID'
  };

  updateendpoint = 'package/addupdate-packages';
  date_search_source: any = 'users';
  date_search_endpoint: any = 'intake/assaylist';
  tablename = 'package';
  editroute = 'admin-dashboard/leads/edit-flowup-list';

  tabledata_detail_skip: any = ['_id'];
  public jwttokenformanagebanner = '';

  statusarray: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  sourcearr: any = [
    {
      val: 'Access Plus Underpayment Recovery',
      name: 'Access Plus Underpayment Recovery',
    },
    { val: 'Aspire Shipping Solution', name: 'Aspire Shipping Solution' },
    { val: 'Aspire Credit Card Audit', name: 'Aspire Credit Card Audit' },
    { val: 'Practice2lab', name: 'Practice2lab' },
    { val: 'Aspire Commercial Payments', name: 'Aspire Commercial Payments' },
  ];

  search_settings: any = {
    datesearch: [
      {
        startdatelabel: 'Search By Created On Start Date',
        enddatelabel: 'Search By Created On End Date',
        submit: 'Search',
        field: 'created_on',
      },
      // {
      //   startdatelabel: 'Search By Start Date',
      //   enddatelabel: 'Search By End Date',
      //   submit: 'Search',
      //   field: 'date',
      // },
    ],

    textsearch: [
      // { label: "Search By Added By", field: 'added_by' },
      // { label: "Search By Time", field: 'start_time' },
    ],
    search: [
      {
        label: 'Search By Added By',
        field: 'added_by',
        values: '',
        serversearchdata: { endpoint: 'user/autocomplete-for-followup' },
      },
      {
        label: 'Search By Added For',
        field: 'added_for',
        values: '',
        serversearchdata: { endpoint: 'leads/autocomplete-lead-name' },
      },
    ],

    selectsearch: [],
  };

  searchendpoint = 'leads/lead-list';

  sortdata: any = {
    type: 'desc',
    field: 'created_on',
    options: [
      'addedby_name',
      'addedfor_name',
      'email',
      'rep_name',
      'status',
      'priority',
      'created_on',
    ],
  };

  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };

  public updatetable: boolean = false;

  libdata: any = {
    basecondition: {},
    detailview_override: [
      { key: 'name', val: 'Name' },
      { key: 'email', val: 'Email' },
      { key: 'phone_number', val: 'Phone No' },
      { key: 'product_name', val: 'Source' },
      { key: 'rep_name', val: 'Associated Rep' },
      { key: 'status', val: 'Status' },
      // { key: "created_on", val: "Created On" },
    ],
    updateendpoint: 'leads/lead-status-change',
    hideeditbutton: false,
    hidedeletebutton: false,
    hideviewbutton: true,

    hidestatustogglebutton: true,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: 'leads/lead-status-change',
    deleteendpointmany: 'followup/followup-delete',
    tableheaders: [
      'addedby_name',
      'addedfor_name',
      'date',
      'start_time',
      'created_on',
    ],
    colpipes: [
      { type: 'datetime', col: 'created_on', format: 'MMMM D YYYY, h:mm A' },
    ],
    custombuttons: [
      {
        label: 'Preview',
        type: 'listner',
        id: 'preview_btn',
        tooltip: 'Preview',
        name: 'preview_btn',
        classname: 'previewButton',
        previewlist: [
          'added_by',
          'addedby_email',
          'addedfor_name',
          'addedfor_email',
          'notes',
          'created_on',
          'updated_on',
        ],
      },
      {
        label: 'Reschedule',
        type: 'listner',
        id: 'Reschedule',
        tooltip: 'Reschedule',
        name: 'Reschedule',
        classname: 'RescheduleButton',
      },

      {
        label: 'Push schedule',
        type: 'listner',
        id: 'Pushschedule',
        tooltip: 'Push schedule',
        name: 'Pushschedule',
        classname: 'Pushschedule',
      },


  
      // this.attendButtonStatus&&{
      //   label: "Attend",
      //   type: 'listner',
      //   id: 'attend_btn',
      //   tooltip: 'Attend',
      //   name: 'attend_btn',
      //   classname: 'attendButton',
      //   cond: 'attend',
      //   condval: false,

      // },

      // {
      //   label: "Folder View",
      //   type: 'listner',
      //   id: 'folderview_btn',
      //   tooltip: 'Folder View',
      //   name: 'folderview_btn',
      //   classname: 'folderviewButton'
      // },
      // {
      //   label: "Followup",
      //   type: 'listner',
      //   id: 'followup_btn',
      //   tooltip: 'Followup',
      //   name: 'followup_btn',
      //   classname: 'followupButton'
      // },
    ],
  };
  navigateFollowup() {
    this.router.navigateByUrl('/admin-dashboard/leads/add-flowup-list');
  }

  ngOnInit() {
    this.paramsId = this.activatedRoute.snapshot.params['id'];
    console.log('paramsId===aaaaa>', this.currentUnixTimestamp);

    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(
        this.cookieService.getAll()['login_user_details']
      );
    }
    // this.tableListData()
    console.log('response===========???????', this.tabledatatalist);

    console.log('this is table list data', this.tabledatatalist);
    this.buttonFlag = 'upcoming';
    this.followupBtnFunction(this.buttonFlag)

    this.tableListData({
      $and: [
        { start_time_unix: { $gte: this.currentUnixTimestamp } },
        { attend: false },
      ],
    });
    this.tableListCount({
      $and: [
        { start_time_unix: { $gte: this.currentUnixTimestamp } },
        { attend: false },
      ],
    });

    this.libdata.basecondition = {
      start_time_unix: { $gte: this.currentUnixTimestamp },
      attend: false,
    };
  }

  tableListData(val: any) {
    let dataobj = {
      condition: {
        limit: 10,
        skip: 0,
      },
      searchcondition: {},
      sort: {
        type: 'asc',
        field: 'start_time_unix',
      },
      project: {},
      token: '',
    };
    dataobj.searchcondition = val;
    this.apiservice
      .getHttpDataPost('followup/follow-list', dataobj)
      .subscribe((response) => {
        if (response.status == 'success') {
          this.loader = false;
          this.tabledatatalist = response?.results?.res
            ? response?.results?.res
            : [];

          if (this.tabledatatalist.length > 0) {
            this.listdataFlag = true;
          } else {
            this.listdataFlag = false;
          }
          console.log('this.listdataFlag', this.listdataFlag);
          console.log('this is table list data', this.tabledatatalist);
        }
      });
  }
  tableListCount(val: any) {
    let countObj = {
      condition: {
        limit: 10,
        skip: 0,
      },
      searchcondition: {},
      sort: {
        type: 'asc',
        field: 'start_time_unix',
      },
      project: {},
      token: '',
    };
    countObj.searchcondition = val;
    this.apiservice
      .getHttpDataPost('followup/follow-list-count', countObj)
      .subscribe((response: any) => {
        if (response && response.count) {
          this.date_search_source_count = response.count; // role data count  save
        }
      });
  }

  followupBtnFunction(e: any) {
    this.loader = true;

    if (e === 'upcoming') {
      this.libdata.hideeditbutton = false;
      this.attendButtonStatus = true;
      this.buttonFlag = 'upcoming';
      // this.tableListData({$and:[{"start_time_unix":{"$gte":this.currentUnixTimestamp}},{attend:false}]})
      // this.tableListCount({$and:[{"start_time_unix":{"$gte":this.currentUnixTimestamp}},{attend:false}]})
      this.libdata.basecondition = {
        start_time_unix: { $gte: this.currentUnixTimestamp },
        attend: false,
      };
      // this.libdata.basecondition={$and:[{"start_time_unix":{"$gte":this.currentUnixTimestamp}},{attend:false}]}
      let cusbtn = {
        label: 'Attend',
        type: 'listner',
        id: 'attend_btn',
        tooltip: 'Mark As Attend',
        name: 'attend_btn',
        classname: 'attendButton',
        cond: 'attend',
        condval: false,
      };
      let is_attendbtn_exist = this.libdata.custombuttons.find(
        (data: any) => data.label == 'Attend'
      );

      !is_attendbtn_exist && this.libdata.custombuttons.push(cusbtn);

      ////////////// If there is no data in any of the tab the listing is not updating because the list isnt initialized, section is done to prevent that ////////////////////

      if (this.tabledatatalist.length == 0) {
        this.tableListData({
          attend: false,
          start_time_unix: { $gte: this.currentUnixTimestamp },
        });

        this.tableListCount({
          attend: false,
          start_time_unix: { $gte: this.currentUnixTimestamp },
        });
      } else {
        this.updatetable = !this.updatetable;
      }
      this.loader = false;
    }
    if (e === 'past') {
      this.buttonFlag = 'past';
      this.libdata.hideeditbutton = true;
      // this.tableListData({attend:true})
      // this.tableListCount({attend:true})
      this.libdata.basecondition = { attend: true };
      let is_attendbtn_exist = this.libdata.custombuttons.find(
        (data: any) => data.label == 'Attend'
      );
      is_attendbtn_exist && this.libdata.custombuttons.splice(1, 1);

      ////////////// If there is no data in any of the tab the listing is not updating because the list isnt initialized, section is done to prevent that ////////////////////
      if (this.tabledatatalist.length == 0) {
        this.tableListData({ attend: true });
        this.tableListCount({ attend: true });
      } else {
        this.updatetable = !this.updatetable;
      }
      this.loader = false;
    }

    if (e === 'missed') {
      this.attendButtonStatus = true;
      this.libdata.hideeditbutton = true;
      this.buttonFlag = 'missed';
      // this.tableListData({$and:[{start_time_unix:{$lt:this.currentUnixTimestamp}},{attend:false}]})
      // this.tableListCount({$and:[{start_time_unix:{$lt:this.currentUnixTimestamp}},{attend:false}]})
      this.libdata.basecondition = {
        start_time_unix: { $lt: this.currentUnixTimestamp },
        attend: false,
      };

      let is_attendbtn_exist = this.libdata.custombuttons.find(
        (data: any) => data.label == 'Attend'
      );
      is_attendbtn_exist && this.libdata.custombuttons.splice(1, 1);

      ////////////// If there is no data in any of the tab the listing is not updating because the list isnt initialized, section is done to prevent that ////////////////////
      if (this.tabledatatalist.length == 0) {
        this.tableListData({
          attend: false,
          start_time_unix: { $lt: this.currentUnixTimestamp },
        });
        this.tableListCount({
          attend: false,
          start_time_unix: { $lt: this.currentUnixTimestamp },
        });
      } else {
        this.updatetable = !this.updatetable;
      }
      this.loader = false;
    }
  }

  // custom buttom click events funtion
  listenLiblistingChange(data: any = null) {
    console.log('onLiblistingButtonChange', data);

    if (
      data.action === 'custombuttonclick' &&
      data.custombuttonclick.btninfo.id === 'attend_btn' &&
      data.custombuttonclick.data
    ) {
      let dilogClose = this.dialog.open(AttendComponent, {
        data: {
          data: data.custombuttonclick.data,
          followFormStatus: this.updatetable,
        },
      });

      dilogClose.afterClosed().subscribe((result) => {
        this.followupBtnFunction('upcoming');
      });
    }

    if (
      data.action === 'custombuttonclick' &&
      data.custombuttonclick.btninfo.id === 'preview_btn' &&
      data.custombuttonclick.data
    ) {
      this.dialog.open(PreviewComponent, {
        data: {
          key: data.custombuttonclick.btninfo.previewlist,
          value: data.custombuttonclick.data,
        },
      });
    }

    if (
      data.action === 'custombuttonclick' &&
      data.custombuttonclick.btninfo.id === 'Reschedule' &&
      data.custombuttonclick.data
    ) {
      let dilogClose = this.dialog.open(RescheduleFollowupComponent, {
        data: {
          data: data.custombuttonclick.data,
          followFormStatus: this.updatetable,
        },
      });

      dilogClose.afterClosed().subscribe((result) => {
        this.followupBtnFunction('upcoming');
      });
    }

    if (
      data.action === 'custombuttonclick' &&
      data.custombuttonclick.btninfo.id === 'Pushschedule' &&
      data.custombuttonclick.data
    ) {
      let dilogClose = this.dialog.open(RescheduleFollowupComponent, {
        data: {
          data: data.custombuttonclick.data,
          followFormStatus: this.updatetable,
          pushsection:true
        },
      });

      dilogClose.afterClosed().subscribe((result) => {
        this.followupBtnFunction('upcoming');
      });
    }



    
  }

  onLiblistingButtonChange(data: any = null) {
    console.log('onLiblistingButtonChange', data);
  }
}
