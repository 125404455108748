import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitelcasePipe } from '../pipes/TitalCasePipe/titelcase.pipe';
import { IsPositiveValuePipe } from '../pipes/ispositivepalue/is-positive-value.pipe';
import { DataTypePipe } from '../pipes/datatypePipe/data-type.pipe';
import { LastLoginInfoComponent } from '../Common-components/last-login-info/last-login-info.component';
import { AppModule } from '../app.module';




@NgModule({
  declarations: [TitelcasePipe,IsPositiveValuePipe,DataTypePipe],
  imports: [
    CommonModule
    
  ],
  exports:[TitelcasePipe,IsPositiveValuePipe,DataTypePipe]
})
export class SharedModuleModule { }
