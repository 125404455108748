import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { buffer, catchError, map, Observable, Subject, Subscription, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiservicesService {
  public baseUrl: any = environment.api_url;
  public googlebaseApi:any = environment.without_base_url
  public jwtToken: string = '';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: '',
    }),
  };
  // httpOptions2 = {
  //   headers: new HttpHeaders({
  //      'Content-Type': 'application/json',
  //      'authorization':  'Basic ' +btoa(environment.username + ':' + environment.password),
  //    })
  //  }

  constructor(private http: HttpClient) { }

  public currentTime: any = new Date().getTime()

  public getHttpData(url: any): Observable<any> {
    let apiUrl = this.baseUrl + url;

      apiUrl += `?timestamp=${this.currentTime}`;

      return this.http.get<any>(apiUrl, this.httpOptions).pipe(
        catchError((error) => {
          console.log('error --->', error);
          return throwError(() => error.error);
        })
      );

    // return this.http.get<any>(this.baseUrl + url, this.httpOptions).pipe(
    //   catchError((error) => {
    //     // this.openSnackBar();
    //     console.log('error --->', error);
    //     return throwError(() => error.error);
    //   })
    // );
    
  }
  public getHttpDataPostWithoutBaseUrl (url: any,body:any): Observable<any> {
    let apiUrl = url;

    apiUrl += `?timestamp=${this.currentTime}`;

    return this.http.post<any>(apiUrl, body).pipe(
      catchError((error) => {
        // this.openSnackBar();
            console.log("this is login error",error);
            
        return throwError(()=>error.error);
      })
    )
  }

  public getHttpDataWithoutBaseUrl(url: any): Observable<any> {
    let apiUrl = url;

    apiUrl += `?timestamp=${this.currentTime}`;
    return this.http.get<any>(apiUrl).pipe(
      catchError((error) => {
        // this.openSnackBar();
        console.log('error --->', error);
        return throwError(() => new Error(error));
      })
    );;
  }

  public getHttpDataPost(url: any, body: any): Observable<any> {
    let apiUrl = this.baseUrl + url;
    apiUrl += `?timestamp=${this.currentTime}`;

    return this.http.post<any>(apiUrl, JSON.stringify(body), this.httpOptions).pipe(
      catchError((error) => {
        // this.openSnackBar();
            console.log("this is login error",error);
            
        return throwError(()=>error.error);
      })
    )
  }

  public getHttpCalendarPost(url: any, body: any): Observable<any> {
    let apiUrl = url;
    apiUrl += `?timestamp=${this.currentTime}`;

    return this.http.post<any>("https://d0fw1rgejc.execute-api.us-east-2.amazonaws.com/production/" + apiUrl, JSON.stringify(body), this.httpOptions).pipe(
      catchError((error) => {
        // this.openSnackBar();
            console.log("this is login error",error);
            
        return throwError(()=>error.error);
      })
    )
  }

  public getHttpGooglePost(url: any, body: any): Observable<any> {
    let apiUrl = url;
    apiUrl += `?timestamp=${this.currentTime}`;

    return this.http.post<any>(this.googlebaseApi + apiUrl, JSON.stringify(body), this.httpOptions).pipe(
      catchError((error) => {
        // this.openSnackBar();
            console.log("this is login error",error);
            
        return throwError(()=>error.error);
      })
    )
  }



  // public getHttpHoroscopePost(url:any,body:any):Observable<any>{
  //   return this.http.post<any>(environment.astrology_api_url+url,JSON.stringify(body),this.httpOptions2).pipe(
  //     catchError((error) => {
  //       // this.openSnackBar();
  //       return throwError(()=>error.error);
  //     })
  //   )
  // }


  httpViaPost(endpoint: any, jsonData: any): Observable<any> {
    // console.log("{{}{}{",jsonData);

    /* set common header */
    let apiUrl = endpoint;
    apiUrl += `?timestamp=${this.currentTime}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': this.jwtToken
      })
    };
    return this.http.post(this.baseUrl + apiUrl, jsonData, httpOptions)

  }


}