<div class="dialoghead">
  <h1 class="mat-mdc-dialog-title">Export Contract</h1>
  <mat-dialog-content>
    <p *ngIf="downloadLink">
      Your download link is ready, click the button below to download
    </p>
  </mat-dialog-content>
  <div class="mat-mdc-dialog-actions">
    <button mat-button (click)="downloadPDF()" *ngIf="downloadLink">
      Download PDF
    </button>
    <mat-progress-spinner
      *ngIf="loader"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>
  <h2 *ngIf="loader">
    Your download link is generating
    <div class="loadingdot_wrap"><span></span><span></span><span></span></div>
  </h2>
  <span
    (click)="closeDialog()"
    mat-button
    mat-dialog-close
    class="close-btn-modal material-icons"
    mat-raised-button
    matTooltip="Close"
    matTooltipPosition="below"
    >close</span
  >
</div>
