<mat-progress-bar mode="indeterminate" *ngIf="newressBar"></mat-progress-bar>
<div class="followup-button-wrapper">
  <button
    mat-button
    (click)="followupBtnFunction('upcoming')"
    [ngClass]="buttonFlag == 'upcoming' ? 'active' : ''"
  >
    Upcoming
  </button>
  <button
    mat-button
    (click)="followupBtnFunction('past')"
    [ngClass]="buttonFlag == 'past' ? 'active' : ''"
  >
    Past
  </button>
  <button
    mat-button
    (click)="followupBtnFunction('missed')"
    [ngClass]="buttonFlag == 'missed' ? 'active' : ''"
  >
    Missed
  </button>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="loader"></mat-progress-bar>
<!-- </mat-card>  -->
<div class="mainwrapper">
  <div class="new-container">
    <div class="subwrapper">
      <div class="sub-title wrapper">
        <h2>Follow Up List</h2>
        <button mat-button class="add_btn"(click)="navigateFollowup()">  Add Follow Up</button>
        </div>
        
      <!-- <div class="noFoundTextinner" *ngIf="!listdataFlag">
        <span class="ng-tns-c1100813557-6">Oops !</span>
        <p class="ng-tns-c1100813557-6">NO Record Found</p>
      </div> -->

      <div class="package">
        <div class="list-style1">
            <div
              class="taxonomies_liststyle"
              [ngClass]="listprogressBar == true ? 'hide' : 'show'"
            >
              <lib-listing
                *ngIf="listdataFlag"
                class="adminlisttable_v1"
                [datasource]="tabledatatalist"
                [modify_header_array]="modify_header_array"
                [apiurl]="api_url_for_managebanner"
                [deleteendpoint]="deleteendpoint"
                [updateendpoint]="updateendpoint"
                [date_search_source]="date_search_source"
                [date_search_endpoint]="date_search_endpoint"
                [sourcedata]="tablename"
                [editroute]="editroute"
                [statusarr]="statusarray"
                [detail_skip_array]="tabledata_detail_skip"
                [jwttoken]="jwttokenformanagebanner"
                [search_settings]="search_settings"
                [searchendpoint]="searchendpoint"
                [sortdata]="sortdata"
                [datacollection]="datacollection"
                [date_search_source_count]="date_search_source_count"
                [libdata]="libdata"
                [limitcond]="limitcond"
                [updatetable]="updatetable"
                (onLiblistingChange)="listenLiblistingChange($event)"
                (onLiblistingButtonChange)="onLiblistingButtonChange($event)"
              >
                >
              </lib-listing>
              <div *ngIf="!listdataFlag" class="noFoundTextinner">
                <span>Oops !</span>
                 <p> NO Record Found</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
