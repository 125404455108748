<button mat-dialog-close class="close-btn-modal" mat-raised-button
matTooltip="Close"
matTooltipPosition="below"><mat-icon>close</mat-icon></button>
<div class="add-file-modal">
  <h2 class="modal-title">
    Showing clicks relative to landing pages and campaigns
  </h2>
  <div class="modal-image-wrapper">
    <div class="inner_wrp">
      <p>Start to End date Range</p>
      <mat-form-field>
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" id="startvalue" [(ngModel)]="startvalue">
          <input matEndDate placeholder="End date" id="endvalue" [(ngModel)]="endvalue">
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <button class="main_button_wrp" (click)="searchdata()">View</button>
    </div>
    <div class="count_value_wrp">

      <div *ngIf="(router.url === '/rep-dashboard/clickreport')|| (router.url === '/admin-dashboard/report/click-report')">
        <h4>Total Click Count:<span>{{clickno}}</span></h4>
      </div>
      <div *ngIf="(router.url === '/rep-dashboard/conversion-report') || (router.url ==='/admin-dashboard/report/conversion-report')">
        <h4>Total Conversion Count:<span>{{conversionno}}</span></h4>
      </div>
     
    </div>
    <div class="header4piechrt ">
      <h1>Click and Conversion Report in Pie Chart View</h1>
    </div>

    <div>
      <app-pie-chart [data]='pieData'></app-pie-chart>
    </div>

  </div>

</div>