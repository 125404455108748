<div class="preview_modal">
  <h1 class="preview_header">preview</h1>
  <mat-dialog-content>
  <div *ngIf="!originval">
    <p class="preview_data">
      <span><strong>Contract Name :</strong></span>
      <span class="preview_value">{{datavalue.name ? datavalue.name : 'N/A'}}</span>
    </p>
    <p class="preview_data">
        <span><strong>Opportunity :</strong></span>
        <span class="preview_value">{{datavalue.opportunity_name ? datavalue.opportunity_name : 'N/A'}}</span>
      </p>
      <p class="preview_data">
        <span><strong>Description :</strong></span>
        <span class="preview_value" [innerHTML]="datavalue.description ? datavalue.description : 'N/A'"></span>
      </p>
      <p class="preview_data">
        <span><strong>Status :</strong></span>
        <span class="preview_value">{{datavalue.status === 1 ? 'Active' : 'Inactive'}}</span>
      </p>
      <p class="preview_data">
        <span><strong>Joined on :</strong></span>
        <span class="preview_value">{{created_on ? created_on : 'N/A'}}</span>
      </p>
  </div>


  <div *ngIf="originval == 'invoice'">
    <p class="preview_data">
      <span><strong>Name :</strong></span>
      <span class="preview_value">{{datavalue.name ? datavalue.name : 'N/A'}}</span>
    </p>
    <!-- <p class="preview_data">
        <span><strong>Invoice Name :</strong></span>
        <span class="preview_value">{{datavalue.invoice_name ? datavalue.invoice_name : 'N/A'}}</span>
      </p> -->
      <p class="preview_data">
        <span><strong>Description :</strong></span>
        <span class="preview_value" [innerHTML]="datavalue.description ? datavalue.description : 'N/A'"></span>
      </p>
      <p class="preview_data">
        <span><strong>Status :</strong></span>
        <span class="preview_value">{{datavalue.status === 1 ? 'Active' : 'Inactive'}}</span>
      </p>
      <p class="preview_data">
        <span><strong>Joined on :</strong></span>
        <span class="preview_value">{{created_on ? created_on : 'N/A'}}</span>
      </p>
  </div>
  <div *ngIf="originval == 'booked_slits'">
    <p class="preview_data">
      <span><strong>Event Title :</strong></span>
      <span class="preview_value">{{datavalue.event_title ? datavalue.event_title : 'N/A'}}</span>
    </p>
    <p class="preview_data">
        <span><strong>Attendee Details :</strong></span>
        <span class="preview_value" class="preview_value" [innerHTML]="datavalue.attendee_details ? datavalue.attendee_details : 'N/A'"></span>
      </p>
      
      <!-- <p class="preview_data">
        <span><strong>Timespan :</strong></span>
        <span class="preview_value">{{datavalue.timespan ? datavalue.timespan : 'N/A'}}</span>
      </p> -->
      <!-- <p class="preview_data">
        <span><strong>Date :</strong></span>
        <span class="preview_value">{{startDateval}}</span>
      </p> -->
      <!-- <p class="preview_data">
        <span><strong>Start Time :</strong></span>
        <span class="preview_value">{{datavalue.start_time ? datavalue.start_time : 'N/A'}}</span>
      </p> -->
      <!-- <p class="preview_data">
        <span><strong>Description :</strong></span>
        <span class="preview_value" [innerHTML]="datavalue.description ? datavalue.description : 'N/A'"></span>
      </p> -->
      <p class="preview_data">
        <span><strong>Schedule :</strong></span>
        <span class="preview_value">{{ this.datavalue.start_end_time_human ?  this.datavalue.start_end_time_human : "N/A"}}</span>
      </p>
      <p class="preview_data">
        <span><strong>Meeting Date :</strong></span>
        <span class="preview_value">{{appointmentTime}}</span>
      </p>
      <p class="preview_data">
        <span><strong>Booked On</strong></span>
        <span class="preview_value">{{bookedTime}}</span>
      </p>
      <p class="preview_data">
        <span><strong>Time Zone :</strong></span>
        <span class="preview_value">{{datavalue.timezone ? datavalue.timezone : 'N/A'}}</span>
      </p>
      <p class="preview_data">
        <span><strong>Notes :</strong></span>
        <span class="preview_value">{{datavalue.notes ? datavalue.notes : 'N/A'}}</span>
      </p>
      
  </div>

  <div *ngIf="originval == 'event_list'">
    <p class="preview_data">
      <span><strong>Event Title :</strong></span>
      <span class="preview_value">{{datavalue.event_title ? datavalue.event_title : 'N/A'}}</span>
    </p>
    <p class="preview_data">
        <span><strong>User Email :</strong></span>
        <span class="preview_value">{{ datavalue.useremail ?  datavalue.useremail : 'N/A'}}</span>
      </p>
      <p class="preview_data">
        <span><strong>Week Day:</strong></span>
        <span class="preview_value">{{day}}</span>
      </p>
      <p class="preview_data">
        <span><strong>Timespan:</strong></span>
        <span class="preview_value">{{datavalue.timespan ? datavalue.timespan : 'N/A'}}</span>
      </p>
      <p class="preview_data">
        <span><strong>Start Date :</strong></span>
        <span class="preview_value">{{startDate}}</span>
      </p>
      <p class="preview_data">
        <span><strong>End Date :</strong></span>
        <span class="preview_value">{{endDate}}</span>
      </p>
      <p class="preview_data">
        <span><strong>Start Time :</strong></span>
        <span class="preview_value">{{datavalue.start_time ? datavalue.start_time : 'N/A'}}</span>
      </p>
      <p class="preview_data">
        <span><strong>End Time :</strong></span>
        <span class="preview_value">{{datavalue.end_time ? datavalue.end_time : 'N/A'}}</span>
      </p>
      <p class="preview_data">
        <span><strong>Description :</strong></span>
        <span class="preview_value" [innerHTML]="datavalue.description ? datavalue.description : 'N/A'"></span>
      </p>
      <p class="preview_data">
        <span><strong>Time Zone :</strong></span>
        <span class="preview_value">{{datavalue.timezone ? datavalue.timezone : 'N/A'}}</span>
      </p>
  </div>
  
  <div *ngIf="originval == 'emailtemplate'">
    <p class="preview_data">
      <span><strong>Subject :</strong></span>
      <span class="preview_value">{{datavalue.subject ? datavalue.subject : 'N/A'}}</span>
    </p>
    <!-- <p class="preview_data">
        <span><strong>Invoice Name :</strong></span>
        <span class="preview_value">{{datavalue.invoice_name ? datavalue.invoice_name : 'N/A'}}</span>
      </p> -->
      <p class="preview_data">
        <span><strong>Description :</strong></span>
        <span class="preview_value" [innerHTML]="datavalue.description ? datavalue.description : 'N/A'"></span>
      </p>
      <p class="preview_data">
        <span><strong>Status :</strong></span>
        <span class="preview_value">{{datavalue.status === 1 ? 'Active' : 'Inactive'}}</span>
      </p>
      <p class="preview_data">
        <span><strong>Joined on :</strong></span>
        <span class="preview_value">{{created_on ? created_on : 'N/A'}}</span>
      </p>
  </div>

  <li *ngIf="data.video">
    <span class="title image_title">Video :</span>
    <span class="image_wraper">
      <section *ngFor="let video of data.video">
        <ng-container
          *ngIf="video.fileservername; then video_first; else video_second"
        ></ng-container>
        <ng-template #video_first>
          <video controls>
            <source
              [src]="
                'https://d37pbainmkhx6o.cloudfront.net/' +
                video.baseurl +
                video.fileservername
              "
            />
          </video>
        </ng-template>
        <ng-template #video_second> N/A </ng-template>
      </section>
    </span>
  </li>

  <!-- <span class="close-btn-modal" (click)="closePreview()">X</span> -->

</mat-dialog-content>
  <span
    mat-button
    mat-dialog-close
    class="close-btn-modal material-icons"
    mat-raised-button
    matTooltip="Close"
    matTooltipPosition="below"
    (click)="closePreview()"
    >close</span
  >
</div>
