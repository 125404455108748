import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListingAngular15Module } from 'listing-angular15';
import { DemoMaterialModule } from '../material/material.module';
import { HeaderComponent } from '../layout/header/header.component';
import { FooterComponent } from '../layout/footer/footer.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LastLoginInfoComponent } from '../Common-components/last-login-info/last-login-info.component';
import { FollowupDetailsComponent } from '../admin-dashboard/leads/folder-view/followup-details/followup-details.component';
import { PieChartComponent } from '../Elements/pie-chart/pie-chart.component';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LastLoginInfoComponent,
    PieChartComponent,
   
    FollowupDetailsComponent
  ],
  imports: [
    NgxMatTimepickerModule,
    CommonModule,
    ListingAngular15Module,
    DemoMaterialModule,
    ReactiveFormsModule

    
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ListingAngular15Module,
    DemoMaterialModule,
    ReactiveFormsModule,
    LastLoginInfoComponent,
    PieChartComponent,
   
    FollowupDetailsComponent
    

  ]
})
export class SharedModule { }
