<div class="attend_main_wraper">
  <div class="attend_heading">
    <p>{{data.pushsection?'Push the': 'Reschedule'}} followup for {{ data.data.addedfor_name }}</p>
  </div>

  <div class="subwrapper">
    <div class="formStyle user-form">
      <mat-card-content
        class="addEditPageWrapper practice_form_style form_title_style"
      >
        <div class="AddEditBlog taxonomies_formstyle">
          <div class="addEditForm addLead">
            <lib-showform
              *ngIf="configFormData != null"
              [formdata]="configFormData"
              [formfieldrefresh]="formfieldrefresh"
              [formfieldrefreshdata]="formfieldrefreshdata"
              (onFormFieldChange)="listenFormFieldChange($event)"
            >
            </lib-showform>
            <!-- <div class="manage_contact_button">
              <button class="mdc-button" (click)="openManageModal()">Manage Contact</button>
            </div> -->
          </div>
          <ng-container *ngIf="formSubmitLoader">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </ng-container>
        </div>
      </mat-card-content>
    </div>
  </div>
  <button class="attend_close" (click)="attendclose()">X</button>
</div>
