import { Component, ElementRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-show-contract',
  templateUrl: './show-contract.component.html',
  styleUrls: ['./show-contract.component.css']
})
export class ShowContractComponent {

  public stepSortedData: any = []

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ShowContractComponent>, private elementRef: ElementRef) {
    console.log(data);
    this.stepSortedData = data?.steps.sort((a: any, b: any) => parseInt(a.order) > parseInt(b.order) ? 1 : -1);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  scroll(id: string) {
    const targetElement = this.elementRef.nativeElement.querySelector(`#${id}`);

    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }
}
