<div class="new-container new-container2">
  <!-- <mat-card class="main-title">
    <p>"Please enter the One-Time Password (OTP) sent to your registered email address in the field below."</p>
  </mat-card> -->
<div class="subwrapper">
    <div class="formStyle user-form">
      <mat-card-content
        class="addEditPageWrapper practice_form_style form_title_style"
      >
        <div class="AddEditBlog taxonomies_formstyle">
          <div class="addEditForm otp_form_container">
            <div class=" otp_new_txt_add_wrppr">
              <p>Please enter the One-Time Password (OTP) sent to your registered email address in the field below</p>
            </div>
            <!-- <lib-showform
            [formdata]="formConfig" 
            (onFormFieldChange)="listenFormFieldChange($event)"
            >
            </lib-showform> -->
            <form class="otp_form" (submit)="onSubmit($event)">
              <p style="width: 400px;" class="otp">
                <mat-form-field appearance="outline">
                  <input matInput placeholder="Placeholder" [(ngModel)]="inputValue" name="inputvalue" >
                </mat-form-field>
              </p>

              <div class="otp_time_out_container otp">
                <span class="otp_time otp_note"> Note: <p>The OTP will expire after 2 minutes</p>  </span>
                <span class="otp_time otp_resend_button">Didn't receive the OTP? Click here to resend {{display?display:'00:00'}} <button mat-button name="resend_button" (click)="handelSubmit('resend_otp')" [disabled]="disabel_button" [ngClass]="disabel_button?'blur':''">Resend OTP</button> </span>
              </div>
              <div class="otp_buttons">
                <button mat-button  name="submit" (click)="handelSubmit('submit')">Submit</button>
                <button mat-button color="primary" name="cancel" (click)="handelSubmit('cancel')">Cancel</button>
              </div>

            </form>


           

            <ng-container *ngIf="loader" >
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </ng-container>
          </div>
        </div> 
      </mat-card-content>
    </div>
  </div>
</div>