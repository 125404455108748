<app-header></app-header>
<div class="mainwrapper trainings_mainwrapper">
  <div class="new-container">
    <div class="subwrapper">
      <div class="sub-title wrapper">
        <h2>Trainings</h2>
      </div>
      <ng-container *ngIf="hideContent">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </ng-container>
      <div class="lesson_prgrscont" *ngIf="!hideContent">
        <div class="prgrscont">
          <div class="prgrscont1">
            <label
              >You have completed
              <span id="done">{{
                trainingPercent.lesson_done - trainingPercent.lesson_not_done
              }}</span
              >/<span id="notdone">{{ trainingPercent.lesson_done }}</span>
              lessons</label
            >
            <div class="loderBodyCon">
              <div class="loderBody">
                <div
                  class="lodercon"
                  [style.width]="trainingPercent.percentage + '%'"
                ></div>
              </div>
              <span
                ><span>{{ trainingPercent.percentage }}%</span>
              </span>
            </div>
          </div>
          <div class="prgrscont2">
            <div class="lessonactivity completed">
              <span></span>
              <label>Completed Lesson</label>
            </div>
            <div class="lessonactivity ongoing">
              <span></span>
              <label>Ongoing Lesson</label>
            </div>
            <div class="lessonactivity incomplete">
              <span></span>
              <label>Incomplete Lesson</label>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper" *ngIf="!hideContent">
        <!-- Required Trainings -->
        <h1 class="training_heading">Required Trainings :</h1>
        <ng-container *ngFor="let data of requiredVideos; let i = index">
          <h2 class="training_categoryname">{{ data.category_name }}</h2>
          <ng-container
            *ngFor="let trainingrequired of data.category_data; let j = index"
          >
            <div
              class="training_datawrap"
              [id]="'requiredTrainingBlock_' + i + '_' + j"
            >
              <div class="training_datawrapinner">
                <h1
                  [ngClass]="{
                    training_title: true,
                    onprogress: trainingrequired.status == 'onprogress',
                    is_completed: trainingrequired.status == 'is_completed',
                    not_done: trainingrequired.status == 'not_done'
                  }"
                  (click)="videoToggle('required', i, j, trainingrequired)"
                >
                  <strong>{{ trainingrequired.title }}</strong>
                  <span class="ng-star-inserted">
                    <i
                      aria-hidden="true"
                      [ngClass]="
                        activeIndex == 'requiredTrainingBlock_' + i + '_' + j
                          ? 'fa fa-chevron-circle-down'
                          : 'fa fa-chevron-circle-right'
                      "
                      class="fa"
                    ></i>
                  </span>
                </h1>
                <div
                  class="training_videowrap"
                  *ngIf="activeIndex == 'requiredTrainingBlock_' + i + '_' + j"
                >
                  <div class="training_video">
                    <video
                      controls
                      poster="https://all-frontend-assets.s3.amazonaws.com/beto_paredes_nest/associate_video_postemimg.jpeg"
                      [autoplay]="trainingrequired.status == 'onprogress'"
                      (ended)="onVideoEnd(trainingrequired._id)"
                    >
                      <source
                        *ngIf="!loader"
                        [src]="trainingrequired.video_url"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <p class="training_desc">
                    {{ trainingrequired.description }}
                  </p>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <!-- Optional Trainings -->
        <h1 class="training_heading">Optional Trainings :</h1>
        <ng-container *ngFor="let data of optionalVideos; let i = index">
          <h2 class="training_categoryname">{{ data.category_name }}</h2>
          <ng-container
            *ngFor="let trainingoptional of data.category_data; let j = index"
          >
            <div
              class="training_datawrap"
              [id]="'optionalTrainingBlock_' + i + '_' + j"
            >
              <div class="training_datawrapinner">
                <h1
                  [ngClass]="{
                    training_title: true,
                    onprogress: trainingoptional.status == 'onprogress',
                    is_completed: trainingoptional.status == 'is_completed',
                    not_done: trainingoptional.status == 'not_done'
                  }"
                  (click)="videoToggle('optional', i, j, trainingoptional)"
                >
                  <strong>{{ trainingoptional.title }}</strong>
                  <span class="ng-star-inserted">
                    <i
                      aria-hidden="true"
                      [ngClass]="
                        activeIndex === 'optionalTrainingBlock_' + i + '_' + j
                          ? 'fa fa-chevron-circle-down'
                          : 'fa fa-chevron-circle-right'
                      "
                      class="fa"
                    ></i>
                  </span>
                </h1>
                <div
                  class="training_videowrap"
                  *ngIf="activeIndex == 'optionalTrainingBlock_' + i + '_' + j"
                >
                  <div class="training_video">
                    <video
                      controls
                      poster="https://all-frontend-assets.s3.amazonaws.com/beto_paredes_nest/associate_video_postemimg.jpeg"
                      [autoplay]="trainingoptional.status == 'onprogress'"
                      (ended)="onVideoEnd(trainingoptional._id)"
                    >
                      <source
                        *ngIf="!loader"
                        [src]="trainingoptional.video_url"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <p class="training_desc">
                    {{ trainingoptional.description }}
                  </p>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="loader" class="training_loaderwrapper">
    <ng-container>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-container>
  </div>
</div>
