<div class="attend_main_wraper">
    <div class="attend_heading">
        <p>Alert !!</p>
    </div>
    <div class="attend_content_wrap">
        <p>Are You Sure You Attend This Lead ?</p>
    </div>
    <div class="attend_btn_wrap">
        <button (click)="confirm()">YES</button>
        <button (click)="attendclose()" style="margin-left: 20px;">NO</button>
    </div>
    <div class="progress_wraper" *ngIf="loader">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <button class="attend_close"  (click)="attendclose()">X</button>
</div>
