<div class="dialoghead">
    <h1 class="mat-mdc-dialog-title"> Alert !!</h1>
    <mat-dialog-content>
      <p>Congratulations on completing all required trainings! Would you like to proceed with the optional training sessions, or would you prefer to go to the dashboard?</p>
    </mat-dialog-content>
    <div class="mat-mdc-dialog-actions">
      <button mat-button (click)="goToDashboard('no')">Proceed to Optional Trainings</button>
      <button mat-button (click)="goToDashboard('yes')">Go to Dashboard</button>
    </div>
  </div>
